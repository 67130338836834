import * as React from 'react';
import ReactLoadable from 'react-loadable';
import { Loading } from './components';

export const Loadable = (loader) => {
  return ReactLoadable({
    loader,
    loading: () => <Loading />,
  } as any);
};
