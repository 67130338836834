import { PaymentMethod, PaymentMethodType } from '@getnoble/noble-consumer-shared';

const creditCards = {
  visa: 'Visa',
  masterCard: 'MasterCard',
  americanExpress: 'American Express',
  discover: 'Discover',
};

export const getImageForCard = (cardType?): string => {
  switch (cardType) {
    case creditCards.visa:
      return '/img/payments/cards/visa.png';
    case creditCards.masterCard:
    case 'Mastercard':
      return '/img/payments/cards/master-card.png';
    case creditCards.americanExpress:
    case 'AmericanExpress':
      return '/img/payments/cards/amex.png';
    case creditCards.discover:
      return '/img/payments/cards/discover.png';
    default:
      return '/img/payments/cards/generic.png';
  }
};

export const getImageSourceForPaymentMethod = (paymentMethod: PaymentMethod): string => {
  if (paymentMethod.type !== PaymentMethodType.Card) {
    return paymentMethod.imageUrl ?? getImageForCard();
  } else {
    return getImageForCard(paymentMethod.creditCardType);
  }
};
