import classnames from 'classnames';
import * as React from 'react';
import './AddPromoCodeRow.scss';

interface AddPromoCodeRowProps {
  className?: string;
  onClick: () => void;
}

export class AddPromoCodeRow extends React.PureComponent<AddPromoCodeRowProps, {}> {
  public render() {
    return (
      <div onClick={this.props.onClick} className={classnames('add-promo-code-row', this.props.className)}>
        <img alt='Promo Code Icon' className='add-promo-code-icon' src='/img/promo-codes/promo-code.png' />
        <span>+ Add Promo Code</span>
      </div>
    );
  }
}
