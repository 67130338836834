import { Actions, DefaultState } from '../constants';
import { AppState } from '../types';

export const onboarding = (state: AppState['onboarding'], action): AppState['onboarding'] => {
  switch (action.type) {
    case Actions.ONBOARDING_START:
      return {
        from: action.payload.from,
        venueId: action.payload.venueId,
        fulfillmentCenterId: action.payload.fulfillmentCenterId,
      };
    case Actions.SIGN_OUT:
      return null;
    default:
      return state || DefaultState.onboarding;
  }
};
