import * as React from 'react';

export const ScheduledIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.8 1.6V0H11.2V1.6H4.8V0H3.2V1.6H0V4.8V6.4V16H16V6.4V4.8V1.6H12.8ZM14.4 14.4H1.6V6.4H14.4V14.4ZM1.6 4.8V3.2H14.4V4.8H1.6Z'
        fill='white'
      />
      <path d='M6.39999 8H4.79999V9.6H6.39999V8Z' fill='white' />
      <path d='M9.6 8H8V9.6H9.6V8Z' fill='white' />
      <path d='M12.8 8H11.2V9.6H12.8V8Z' fill='white' />
      <path d='M4.80001 11.2H3.20001V12.8H4.80001V11.2Z' fill='white' />
      <path d='M7.99999 11.2H6.39999V12.8H7.99999V11.2Z' fill='white' />
      <path d='M11.2 11.2H9.60001V12.8H11.2V11.2Z' fill='white' />
    </svg>
  );
};
