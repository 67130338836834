import * as React from 'react';

type ErrorListProps = {
  errors?: string[] | null;
};

export const ErrorList: React.FC<ErrorListProps> = ({ errors }) => {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <>
      {errors?.map((err) => (
        <p key={err} style={{ color: 'red', fontSize: '1.6rem' }}>
          {err}
        </p>
      ))}
    </>
  );
};
