import * as React from 'react';
import { Loading, ErrorOverlay, DarkButton, Layout, Navbar } from '../../../components';
import * as types from '../../../types';
import { ErrorList } from '../ErrorList';
import './CardInputSpreedly.scss';
import appConfig from '../../../config';
// import { Logger } from '../../../Logger';

interface CardInputSquareBaseProps {
  onSave({ nonce, billingZipCode }: { nonce: string; billingZipCode: string });
  onCancel();
  title: string;
  error?: Error;
  clearError();
  saving: boolean;
}

declare let Spreedly;

export const CardInputSpreedlyBase: React.FC<CardInputSquareBaseProps> = (props) => {
  const [isReady, setIsReady] = React.useState(false);
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [zip, setZip] = React.useState('');
  const [errors, setErrors] = React.useState<null | string[]>(null);

  const numberRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    setTimeout(() => {
      Spreedly.init(appConfig.SPREEDLY_APP_ID, {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv',
      });

      Spreedly.on('ready', function () {
        setIsReady(true);

        Spreedly.setParam('allow_blank_name', true);
        Spreedly.setStyle('number', 'width: 100%;  height:35px; color: #fff; border-bottom: 1px solid #fff');
        Spreedly.setStyle('number', 'font-size: 20px;');
        Spreedly.setStyle('cvv', 'width: 100%;  height:35px; color: #fff; border-bottom: 1px solid #fff');
      });

      Spreedly.on('errors', function (errors) {
        setErrors(errors.map((e) => e.message ?? e.toString()));
      });

      Spreedly.on('paymentMethod', function (token, pmData) {
        props.onSave({ nonce: token, billingZipCode: pmData.zip ?? '' });
      });
    }, 500);

    return () => {
      Spreedly.removeHandlers();
    };
  }, []);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();

      var requiredFields = {
        month,
        year: year.length === 2 ? '20' + year : year,
        first_name: '',
        last_name: '',
        zip,
      };

      Spreedly.tokenizeCreditCard(requiredFields);
    },
    [month, zip, year]
  );

  return (
    <Layout title={props.title}>
      <Navbar>
        <Navbar.Button onClick={props.onCancel} type={types.NavbarButtonType.chevron} />
        <Navbar.Title>{props.title}</Navbar.Title>
      </Navbar>
      <form id='payment-form' onSubmit={onSubmit}>
        <div>
          <div className='spreedly-form-row'>
            <div className='spreedly-form-col'>
              <label>Credit Card Number</label>
              <div ref={numberRef} id='spreedly-number'></div>
            </div>
          </div>
          <div className='spreedly-form-row'>
            <div className='spreedly-form-col'>
              <label htmlFor='month'>Expiration Date</label>
              <input
                className='spreedly-form-date'
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                type='text'
                id='month'
                name='month'
              />
              <input
                className='spreedly-form-date'
                value={year}
                onChange={(e) => setYear(e.target.value)}
                type='text'
                id='year'
                name='year'
                maxLength={4}
              />
            </div>
            <div className='spreedly-form-col'>
              <label>CVV</label>
              <div id='spreedly-cvv'></div>
            </div>
          </div>
          <div className='spreedly-form-row'>
            <div className='spreedly-form-col'>
              <label htmlFor='month'>Postal Code</label>
              <input
                className='spreedly-form-date'
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                type='text'
                id='zip'
                name='zip'
              />
            </div>
          </div>
        </div>
        <ErrorList errors={errors} />
        <DarkButton disabled={!isReady} className='bottom-button' type='submit'>
          SAVE
        </DarkButton>
        <ErrorOverlay error={props.error} onClickButton={props.clearError} />
        {props.saving && <Loading />}
      </form>
    </Layout>
  );
};
