import classnames from 'classnames';
import * as React from 'react';
import './PaymentMethodButton.scss';

interface PaymentMethodButtonProps {
  onClick: () => void;
  className?: string;
  testId?: string;
}

export const PaymentMethodButton: React.FC<PaymentMethodButtonProps> = ({ onClick, className, children, testId }) => {
  return (
    <div data-test-id={testId} className={classnames('payment-method-button', className)} onClick={onClick}>
      {children}
    </div>
  );
};
