import { Actions, DefaultState } from '../constants';
import { PromoCodesState } from '../types';

export const promoCodes = (state: PromoCodesState, action): PromoCodesState => {
  switch (action.type) {
    case Actions.SIGN_OUT:
      return {
        promoCodes: [],
      };
    case Actions.SAVE_PROMO_CODE:
      return {
        promoCodes: [...state.promoCodes, action.payload.promoCode],
      };
    default:
      return state || DefaultState.promoCodes;
  }
};
