import { Loadable } from '../Loadable';

export const AsyncSplash = Loadable(() => import('./Splash'));
export const AsyncLogin = Loadable(() => import('./Login'));
export const AsyncRegister = Loadable(() => import('./Register'));
export const AsyncForgotPassword = Loadable(() => import('./ForgotPassword'));
export const AsyncSocialLanding = Loadable(() => import('./SocialLanding'));
// export const AsyncVerifyPhoneNumber = Loadable(() => import('./VerifyPhoneNumber'));
export const AsyncVerifyPhoneNumberPasswordless = Loadable(() => import('./VerifyPhoneNumberPasswordless'));
export const AsyncProfileSetup = Loadable(() => import('./ProfileSetup'));
