import * as React from 'react';
import './ErrorRow.scss';

export const ErrorRow = ({ text }: { text: string }) => {
  return (
    <div className='generic-error-row'>
      <div className='generic-error-row-icon'>!</div>
      <div className='generic-error-row-text'>{text}</div>
    </div>
  );
};
