import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { Loading } from '../components';
import './ThemeWrapper.scss';
import { useLocation, matchPath } from 'react-router-dom';
import { VenueStyleQuery, VenueStyleQueryVariables } from './__generated__/VenueStyleQuery';

type ThemeWrapperProps = React.PropsWithChildren<{}>;

export const query = gql`
  query VenueStyleQuery($venueId: ID!) {
    venueStyle(venueId: $venueId) {
      backgroundColor
      backgroundImageUrl
      categoryTitleColor
      topBar {
        backgroundColor
        bottomBorder {
          color
          width
        }
        textColor
      }
      cartBar {
        backgroundColor
        topBorder {
          color
          width
        }
        textColor
      }
      venueTop {
        hideTitle
        photo
        hideShadow
      }
      categoryTiles {
        backgroundColor
      }
    }
  }
`;

const getVenueId = (pathname: string | undefined): string | undefined => {
  if (!pathname) {
    return;
  }

  const matchedVenueId = matchPath<{ venueId: string }>(pathname, {
    path: `/venue/:venueId`,
  });
  return matchedVenueId?.params?.venueId;
};

export const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
  const location = useLocation();
  const venueId = getVenueId(location?.pathname);
  const { loading, data } = useQuery<VenueStyleQuery, VenueStyleQueryVariables>(query, {
    skip: !venueId,
    variables: {
      venueId: venueId!,
    },
  });

  React.useEffect(() => {
    const menuStyle = data?.venueStyle;
    if (menuStyle) {
      const customStyleVars = {
        '--cart-item-border-color': 'rgba(255, 255, 255, 0.2)',
        '--cart-item-background-color': menuStyle?.cartBar.backgroundColor,
        '--cart-item-foreground-color': menuStyle?.cartBar.textColor,
        '--cart-item-quantity-bg-color': menuStyle?.cartBar.backgroundColor,
        '--cart-wrapper-border-color': menuStyle?.cartBar.topBorder?.color,
        // '--order-summary-tip-bg-color': '#ff00ff',
        '--order-summary-tip-selected-bg-color': menuStyle?.cartBar.backgroundColor,
        '--order-summary-tip-selected-text-color': menuStyle?.cartBar.textColor,
        '--promo-badge-bg-color': 'red',
        '--promo-badge-text-color': '#fff',
        '--segmented-button-selected-border': menuStyle?.cartBar.topBorder?.color,
        '--primary-btn-bg-color': menuStyle?.cartBar.backgroundColor,
        '--primary-btn-bg-disabled-color': menuStyle?.cartBar.backgroundColor,
        '--primary-btn-text-color': menuStyle?.cartBar.textColor,
        // '--order-summary-pay-row-bg-color': "",
        '--order-summary-pay-row-empty-text-color': menuStyle.topBar.textColor,
        '--item-detail-image-border-color': menuStyle.cartBar.topBorder?.color,
        '--item-detail-tile-background-color': menuStyle.cartBar.backgroundColor,
        '--item-detail-tile-text-color': menuStyle.cartBar.textColor,
        '--item-detail-modifier-selected-bg-color': menuStyle.cartBar.backgroundColor,
        '--item-detail-modifier-selected-text-color': menuStyle?.cartBar.textColor,
        '--screen-dark-bg': menuStyle.backgroundColor,
        '--screen-dark-grey-bg': menuStyle.backgroundColor,
        '--screen-lighter-dark-blue-bg': menuStyle.backgroundColor,
        '--dark-navigation-bar-color': '#333',
        '--fulfillment-center-row-bg-color': menuStyle.topBar.backgroundColor,
      };

      Object.entries(customStyleVars).forEach(([prop, value]) => {
        if (value) {
          document.querySelector('body')!.style.setProperty(prop, value);
        }
      });

      return () => {
        Object.entries(customStyleVars).forEach(([prop, value]) => {
          if (value) {
            document.querySelector('body')!.style.removeProperty(prop);
          }
        });
      };
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  return <>{React.Children.only(children)}</>;
};
