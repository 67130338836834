import { Order } from '@getnoble/noble-consumer-shared';
import * as types from '../types';

export const getOrderFromState = ({
  state,
  fulfillmentCenterId,
}: {
  state: types.AppState;
  fulfillmentCenterId: string;
}): Order | null => {
  return getOrderFromOrderState({ orders: state.orders, fulfillmentCenterId });
};

export const getOrderFromOrderState = ({
  orders,
  fulfillmentCenterId,
}: {
  orders: types.OrderState;
  fulfillmentCenterId: string;
}): Order | null => {
  return orders[fulfillmentCenterId] ? orders[fulfillmentCenterId].order : null;
};
