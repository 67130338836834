import * as React from 'react';
import { isApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { errorUtils } from '../../utils';
import { Overlay } from '../Overlay';
import './ErrorOverlay.scss';

interface BaseErrorOverlayProps {
  error: Error | null | undefined;
  buttonText?: string;
}

interface LinkedErrorOverlayProps extends BaseErrorOverlayProps {
  href: string;
}

interface ActionErrorOverlayProps extends BaseErrorOverlayProps {
  onClickButton: () => void;
}

type ErrorOverlayProps = LinkedErrorOverlayProps | ActionErrorOverlayProps;

const isLinkedErrorOverlayProps = (props: ErrorOverlayProps): props is LinkedErrorOverlayProps => {
  return (props as LinkedErrorOverlayProps).href !== undefined;
};

const defaultTitle = 'Oops, there seems to be an issue.';

export const infoForError = (error) => {
  let title = defaultTitle;
  let message = error.message;

  if (isApolloError(error)) {
    if (error.networkError?.name === 'ServerParseError') {
      return {
        title: defaultTitle,
        message: 'Please check your internet settings',
      };
    }

    const display = errorUtils.getAPIDisplayTitleAndMessage(error);
    if (display) {
      return {
        title: display.title || defaultTitle,
        message: display.message,
      };
    }
  }

  if (error.title && error.message) {
    title = error.title;
    message = error.message;
  }

  if (error.json && error.json.error) {
    message = error.json.error;
  }

  return { title, message };
};

const ErrorOverlayContent: React.FC<ErrorOverlayProps> = (props) => {
  const { error, buttonText } = props;
  const history = useHistory();

  const onClick = React.useCallback(() => {
    if (isLinkedErrorOverlayProps(props)) {
      history.push(props.href);
    } else {
      props.onClickButton();
    }
  }, [props, history]);

  if (!error) {
    return null;
  }

  const { title, message } = infoForError(error);

  return (
    <div>
      <h3 className='error-heading'>{title}</h3>
      <p className='error-text'>{message}</p>
      <button onClick={onClick} className='btn error-btn'>
        {buttonText || 'GOT IT'}
      </button>
    </div>
  );
};

export const ErrorOverlay: React.FC<ErrorOverlayProps> = (props) => {
  return (
    <Overlay open={!!props.error}>
      <ErrorOverlayContent {...props} />
    </Overlay>
  );
};
