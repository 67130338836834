import * as React from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { ErrorRow } from '../ErrorRow';
import './FloatingLabelTextInput.scss';

interface FloatingLabelTextInputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  name: string;
  type?: string;
  errorText?: string;
  mask?: string;
  maskPlaceholder?: string;
  inputMode?: string;
}

export const FloatingLabelTextInput: React.FC<FloatingLabelTextInputProps> = ({
  maskPlaceholder,
  mask,
  errorText,
  type,
  value,
  name,
  label,
  onChange,
  inputMode,
}) => {
  const [focused, setFocused] = React.useState(false);
  const onChangeInput = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  const onFocus = React.useCallback(() => {
    setFocused(true);
  }, [setFocused]);

  const onBlur = React.useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  const [passwordTypeOverriden, setPasswordTypeOverriden] = React.useState(false);
  const togglePasswordTypeOverride = React.useCallback(() => {
    setPasswordTypeOverriden(!passwordTypeOverriden);
  }, [passwordTypeOverriden, setPasswordTypeOverriden]);

  let passwordToggleButton;
  if (type === 'password') {
    passwordToggleButton = (
      <button type='button' onClick={togglePasswordTypeOverride} className='floating-label-input-group-password-toggle'>
        {passwordTypeOverriden ? 'Hide' : 'Show'}
      </button>
    );
  }

  const groupClassName = classnames('floating-label-input-group', {
    'floating-label-input-group-filled': !!value.length || focused,
  });

  const finalType = type === 'password' ? (passwordTypeOverriden ? undefined : 'password') : type;

  const InputEl: any = mask ? InputMask : 'input';
  let maskProps = {};
  if (mask) {
    maskProps = {
      maskChar: ' ',
      maskPlaceholder,
      mask,
    };
  }

  return (
    <div className={groupClassName}>
      <label htmlFor='email'>{label}</label>
      <InputEl
        onFocus={onFocus}
        onBlur={onBlur}
        type={finalType}
        onChange={onChangeInput}
        name={name}
        value={value}
        inputMode={inputMode}
        {...maskProps}
      />
      {passwordToggleButton}
      {errorText && <ErrorRow text={errorText} />}
    </div>
  );
};
