import client from 'braintree-web/client';
import hostedFields from 'braintree-web/hosted-fields';
import { MutationFunction } from '@apollo/client';
import { CreditCardType } from '@getnoble/noble-consumer-shared';
import * as React from 'react';
import { DarkButton, ErrorOverlay, Layout, Loading, Navbar, Checkbox } from '../../../components';
import * as types from '../../../types';
import config from '../../../config';
import { Logger } from '../../../Logger';
import { PaymentMethodToken } from './__generated__/PaymentMethodToken';
import { SavePaymentMethod, SavePaymentMethodVariables } from './__generated__/SavePaymentMethod';
import './CardInputBraintree.scss';
import { getImageForCard } from './utils';

interface CardInputBraintreeProps {
  loadingToken: boolean;
  savingCard: boolean;
  title: string;
  tokenError?: Error;
  saveError?: Error;

  data: PaymentMethodToken;
  retryFetchToken();
  onSave: MutationFunction<SavePaymentMethod, SavePaymentMethodVariables>;
  onSubmitOneTimeCard: any; //?(card: PaymentMethod);
  onCancel();
}

interface CardInputBraintreeState {
  cardType: CreditCardType;
  isValid: boolean;
  error: Error | null;
  saveCardForLater: boolean;
}

const covertBraintreeType = (type): CreditCardType => {
  switch (type) {
    case 'visa':
      return CreditCardType.visa;
    case 'american-express':
      return CreditCardType.americanExpress;
    case 'discover':
      return CreditCardType.discover;
    case 'master-card':
      return CreditCardType.masterCard;
    default:
      return CreditCardType.unknown;
  }
};

export class CardInputBraintree extends React.Component<CardInputBraintreeProps, CardInputBraintreeState> {
  public hostedFieldsInstance;

  constructor(props) {
    super(props);

    this.state = {
      cardType: CreditCardType.unknown,
      isValid: false,
      error: null,
      saveCardForLater: false,
    };
  }

  public setupBraintree = async (token) => {
    try {
      const clientInstance = await client.create({ authorization: token });
      this.hostedFieldsInstance = await hostedFields.create({
        client: clientInstance,
        styles: {
          input: {
            'font-size': '1rem',
            color: '#fff',
          },
        },
        options: {
          validate: true,
        },
        fields: {
          number: {
            selector: '#card-number',
            placeholder: 'Card Number',
            prefill: config.BT_PREFILL.number,
          },
          cvv: {
            selector: '#cvv',
            placeholder: 'CVV',
            prefill: config.BT_PREFILL.cvv,
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YYYY',
            prefill: config.BT_PREFILL.expirationDate,
          },
          postalCode: {
            selector: '#postal-code',
            placeholder: 'Zip Code',
            prefill: config.BT_PREFILL.postalCode,
          },
        },
      });

      this.hostedFieldsInstance.on('cardTypeChange', (event) => {
        if (event && event.cards && event.cards[0]) {
          this.setState({ cardType: covertBraintreeType(event.cards[0].type) });
        }
      });

      this.hostedFieldsInstance.on('validityChange', () => {
        const state = this.hostedFieldsInstance.getState();
        const isValid = Object.keys(state.fields).every((key) => state.fields[key].isValid);
        this.setState({ isValid });
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  public async componentDidMount() {
    if (this.props.data && this.props.data.paymentMethodToken) {
      this.setupBraintree(this.props.data.paymentMethodToken);
    }
  }

  public componentDidUpdate(prevProps) {
    const prevToken = prevProps.data ? prevProps.data.paymentMethodToken : undefined;
    const newToken = this.props.data ? this.props.data.paymentMethodToken : undefined;
    if (prevToken !== newToken) {
      this.setupBraintree(this.props.data.paymentMethodToken);
    }

    if (this.props.saveError && !prevProps.saveError) {
      this.setState({ error: this.props.saveError });
    }

    if (this.props.tokenError && !prevProps.tokenError) {
      this.setState({ error: this.props.tokenError });
    }
  }

  public clearError = () => {
    if (this.props.tokenError) {
      return this.props.retryFetchToken();
    }

    this.setState({ error: null });
  };

  public onSubmit = async (e) => {
    e.preventDefault();

    if (!this.state.isValid) {
      this.setState({ error: Error('Please check your card details') });
      return;
    }

    try {
      // , details
      const { nonce } = await this.hostedFieldsInstance.tokenize();
      await this.props.onSave({
        variables: { nonce },
        onError: (error) => {
          this.setState({ error });
        },
      });
      //   this.props.onSubmitOneTimeCard({
      //     identifier: details.lastFour,
      //     isDefault: false,
      //     braintreeToken: nonce,
      //     freedomPayToken: null,
      //     type: PaymentMethodType.Card,
      //     creditCardType: details.cardType,
      //     squareToken: null,
      //     imageUrl: null,
      //   });
      // }
    } catch (error) {
      Logger.error(error);
    }
  };

  public onToggleSaveCardForLater = (saveCardForLater: boolean) => {
    this.setState({ saveCardForLater });
  };

  public render() {
    if (this.props.loadingToken) {
      return <Loading />;
    }

    return (
      <Layout backgroundColorVariant='dark' title={this.props.title}>
        <Navbar>
          <Navbar.Button type={types.NavbarButtonType.chevron} onClick={this.props.onCancel} />
          <Navbar.Title>{this.props.title}</Navbar.Title>
        </Navbar>
        <form onSubmit={this.onSubmit}>
          <div className='card-input-card'>
            <div className='card-input-card-number-row'>
              <img src={getImageForCard(this.state.cardType)} alt='' />
              <div className='card-input-input' id='card-number' />
            </div>
            <div className='card-input-split-row'>
              <div id='expiration-date' className='card-input-input' />
              <div id='cvv' className='card-input-input' />
            </div>
            <div id='postal-code' className='card-input-input' />
            {this.props.onSubmitOneTimeCard && (
              <Checkbox
                id='save-for-later'
                label='Save this card for future orders'
                checked={this.state.saveCardForLater}
                onChange={this.onToggleSaveCardForLater}
              />
            )}
          </div>
          <DarkButton className='bottom-button' type='submit'>
            SAVE
          </DarkButton>
          <ErrorOverlay error={this.state.error} onClickButton={this.clearError} />
          {this.props.savingCard && <Loading />}
        </form>
      </Layout>
    );
  }
}
