import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RouteComponentProps } from 'react-router';
import { ScreenLoading } from './components';
import { analytics } from './services';
import Routes from './Routes';
import { persistor, store } from './store';
import { ThemeWrapper } from './ThemeWrapper';
import './styles/index.scss';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5ddb3c642a254a59b99ce4acad527a14@sentry.io/1380022',
  });
}

interface AppProps extends RouteComponentProps {
  client: any;
}

interface AppState {
  error: Error | null;
}

const showSentryDialog = () => {
  Sentry.showReportDialog();
};

class AppUnwrapped extends React.Component<AppProps, AppState> {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  public async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      await analytics.recordScreenView(this.props.location.pathname);
    }
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  public componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, (scope) => {
      scope.clear();
      scope.setContext('REACT COMPONENT INFO', {
        componentStack: errorInfo.componentStack,
      });

      return scope;
    });
  }

  public render() {
    if (this.state.error) {
      return (
        <div className='app-error-boundary' onClick={showSentryDialog}>
          Sorry, something went wrong. Please help us by tapping here to provide any feedback
        </div>
      );
    }

    return (
      <ApolloProvider client={this.props.client}>
        <Provider store={store}>
          <PersistGate loading={<ScreenLoading />} persistor={persistor}>
            <ThemeWrapper>
              <Routes />
            </ThemeWrapper>
          </PersistGate>
        </Provider>
      </ApolloProvider>
    );
  }
}

export const App = withRouter(AppUnwrapped);
