import * as React from 'react';

export const useErrorWrapper = (parentError: Error | undefined) => {
  const [error, setError] = React.useState<Error | undefined>(parentError);
  const lastError = React.useRef(parentError);

  React.useEffect(() => {
    if (parentError !== lastError.current && parentError) {
      setError(parentError);
    }

    lastError.current = parentError;
  }, [parentError]);

  const clearError = React.useCallback(() => {
    setError(undefined);
  }, [setError]);

  return { error, clearError };
};
