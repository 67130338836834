import * as React from 'react';
import { UpdateDrinkDetailAppConfigQuery as DrinkDetailData } from '../../order/UpdateDrinkDetail/__generated__/UpdateDrinkDetailAppConfigQuery';

type DescriptionRowProps = {
  item: DrinkDetailData['menuItem'];
};

export const DescriptionRow: React.FC<DescriptionRowProps> = ({ item }) => {
  if (!item.description) {
    return null;
  }

  const formattedDesc = item.description.replace(new RegExp('\r?\n', 'g'), '<br />');
  return <p className='drink-detail__desc' dangerouslySetInnerHTML={{ __html: formattedDesc }} />;
};
