import * as React from 'react';
import { RadioRow } from './RadioRow';
import { OptionGroup, Option } from '../types';

interface RadioMenuListProps {
  optionGroup: OptionGroup;
  selected: Option[];
  onSelect: (option: Option) => void;
}

export const RadioMenuList: React.FC<RadioMenuListProps> = (props) => {
  const { optionGroup, selected, onSelect } = props;
  if (optionGroup.options.length === 0) {
    return null;
  }

  const selectedIds = selected.map((o) => o.optionId);

  const els = optionGroup.options.map((option) => (
    <RadioRow
      option={option}
      selected={selectedIds.includes(option.optionId)}
      onClick={onSelect}
      key={option.optionId}
    />
  ));

  return (
    <section className='radio-menu-list'>
      <h6 className='drink-detail-header-row'>{optionGroup.name}</h6>
      {els}
    </section>
  );
};
