import { gql } from '@apollo/client';
import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { CardInputFreedomPay as CardInputFreedomPayComponent } from './CardInputFreedomPay';
import { CardInputProps } from '../../types';

export const query = gql`
  query HPCForm($venueId: ID!) {
    hpcForm(venueId: $venueId) {
      sessionKey
      formHTML
    }
  }
`;

export const mutation = gql`
  mutation AddHPCCardToWallet($venueId: ID!, $paymentKey: String!, $sessionKey: String!, $cardType: String!) {
    addHPCCardToWallet(venueId: $venueId, paymentKey: $paymentKey, sessionKey: $sessionKey, cardType: $cardType)
  }
`;

export const CardInputFreedomPay: React.FC<CardInputProps> = ({ onCancel, title, venueId, onSave }) => {
  const [error, setError] = React.useState<Error | undefined>(undefined);
  const [addCardToWallet, addCardToWalletState] = useMutation(mutation, {
    onCompleted: onSave,
    onError() {
      setError(new Error('Please check your card info'));
    },
  });

  const hpcQueryInfo = useQuery(query, {
    fetchPolicy: 'no-cache',
    variables: { venueId },
    onError(error) {
      setError(error);
    },
  });

  const clearError = React.useCallback(() => {
    if (hpcQueryInfo.error) {
      onCancel();
    } else if (addCardToWalletState.error) {
      setError(undefined);
    }
  }, [addCardToWalletState.error, hpcQueryInfo.error, onCancel]);

  return (
    <CardInputFreedomPayComponent
      title={title}
      clearError={clearError}
      loadingForm={hpcQueryInfo.loading}
      savingCard={addCardToWalletState.loading}
      onCancel={onCancel}
      venueId={venueId}
      error={error}
      data={hpcQueryInfo.data}
      onSave={addCardToWallet}
    />
  );
};
