import classnames from 'classnames';
import * as React from 'react';
import './OutlineButton.scss';

interface OutlineButtonProps {
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
}

export class OutlineButton extends React.PureComponent<OutlineButtonProps, {}> {
  public render() {
    const { onClick, type, className, children } = this.props;

    return (
      <button onClick={onClick} type={type} className={classnames('btn', 'btn-outline-blue', className)}>
        {children}
      </button>
    );
  }
}
