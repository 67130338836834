import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useIsLoggedIn } from '../hooks';
import { Redirect, Route } from 'react-router-dom';
import { urlUtils } from '../utils';
import * as actions from '../actions';

class PrivateRouteUnwrapped extends React.PureComponent<any> {
  componentDidMount() {
    if (!this.props.isLoggedIn) {
      const params = this.props.computedMatch?.params ?? {};
      this.props.setFromForOnboarding({
        venueId: params.venueId,
        fulfillmentCenterId: params.fulfillmentCenterId,
      });
    }
  }

  public renderRoute = (props: any) => {
    if (this.props.isLoggedIn) {
      const { component: Component } = this.props;

      return <Component {...props} />;
    } else {
      const { params } = props.match;
      const pathname = params.venueId
        ? urlUtils.onboardingUrl({ venueId: params.venueId, fulfillmentCenterId: params.fulfillmentCenterId })
        : '/';
      return <Redirect to={{ pathname }} />;
    }
  };

  public render() {
    const { component, isLoggedIn, ...rest } = this.props;

    return <Route {...rest} render={this.renderRoute} />;
  }
}

export const PrivateRoute: React.FC<any> = (props) => {
  const { location } = props;
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();
  const setFromForOnboarding = React.useCallback(
    ({ venueId, fulfillmentCenterId }) => {
      dispatch(
        actions.setFromForOnboarding({
          from: location.pathname,
          venueId,
          fulfillmentCenterId,
        })
      );
    },
    [dispatch]
  );

  return <PrivateRouteUnwrapped isLoggedIn={isLoggedIn} setFromForOnboarding={setFromForOnboarding} {...props} />;
};
