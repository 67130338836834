import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import { CardInputProps } from '../../types';
import { useApolloErrorWrapper } from '../../../hooks';
import { CardInputSpreedlyBase } from './CardInputSpreedly';
import {
  SaveSpreedlyPaymentMethod,
  SaveSpreedlyPaymentMethodVariables,
} from './__generated__/SaveSpreedlyPaymentMethod';

import useScript from './hooks';

const MUTATION = gql`
  mutation SaveSpreedlyPaymentMethod($posType: String!, $nonce: String!, $venueId: ID!, $billingZipCode: String!) {
    savePOSPaymentMethod(posType: $posType, nonce: $nonce, venueId: $venueId, billingZipCode: $billingZipCode)
  }
`;

export const CardInputSpreedly: React.FC<CardInputProps> = (props) => {
  const { venueId } = props;
  const { onError, clearError, error } = useApolloErrorWrapper();

  const [saveSquareCard, { loading: savingSquareCard }] = useMutation<
    SaveSpreedlyPaymentMethod,
    SaveSpreedlyPaymentMethodVariables
  >(MUTATION, {
    onError,
    onCompleted() {
      props.onSave();
    },
  });

  const onSave = React.useCallback(
    ({ nonce, billingZipCode }) => {
      saveSquareCard({
        variables: {
          nonce,
          venueId,
          posType: 'spreedly',
          billingZipCode,
        },
      });
    },
    [venueId, saveSquareCard]
  );

  const [loading, setLoading] = React.useState(true);
  const scriptUrl = 'https://core.spreedly.com/iframe/iframe-v1.min.js';
  const [scriptLoaded, scriptError] = useScript(scriptUrl);

  React.useEffect(() => {
    if (scriptLoaded || scriptError) {
      setLoading(false);
    }
  }, [scriptLoaded, scriptError]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <CardInputSpreedlyBase
      saving={savingSquareCard}
      error={error}
      clearError={clearError}
      title={props.title}
      onCancel={props.onCancel}
      onSave={onSave}
    />
  );
};
