import { ApolloError } from '@apollo/client';

export const getAPICode = (error: ApolloError): string | undefined => {
  if (
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].extensions &&
    error.graphQLErrors[0].extensions.display
  ) {
    return error.graphQLErrors[0].extensions.display.code;
  }
};

export const getAPIDisplayTitleAndMessage = (error: ApolloError): { title?: string; message: string } | undefined => {
  if (error.graphQLErrors && error.graphQLErrors[0]) {
    if (error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.display) {
      return {
        title: error.graphQLErrors[0].extensions.display.title,
        message: error.graphQLErrors[0].extensions.display.message,
      };
    } else if (error.graphQLErrors[0].extensions && error.graphQLErrors[0].extensions.message) {
      return {
        message: error.graphQLErrors[0].extensions.message,
      };
    } else {
      return {
        message: error.graphQLErrors[0].message,
      };
    }
  }
};
