import * as React from 'react';
import { Overlay } from '../Overlay';
import { DarkButton } from '../DarkButton';
import { OutlineButton } from '../OutlineButton';
import './DrinkLimitOverlay.scss';

const COPY_PREFIX =
  'You are ordering more than can be sold to one customer in a single order. ' +
  'By ordering, you agree that additional customers will pick-up these drinks to meet the ';

const COPY_SUFFIX = ' per customer ratio.';

interface DrinkLimitOverlayProps {
  open: boolean;
  drinkLimit: number;

  onPressAgree();
  onPressDisagree();
}

export const DrinkLimitOverlay: React.FC<DrinkLimitOverlayProps> = ({
  open,
  onPressAgree,
  onPressDisagree,
  drinkLimit,
}) => {
  return (
    <Overlay open={open}>
      <div className='drink-limit-overlay-icon'>
        <img src='/img/drink-limit-overlay/alert-icon.png' />
      </div>
      <h4 className='drink-limit-overlay-title'>Drink ordering limits</h4>
      <p className='drink-limit-overlay-message'>
        {COPY_PREFIX} <b>{drinkLimit} drinks</b> {COPY_SUFFIX}
      </p>
      <DarkButton className='mt-1' onClick={onPressAgree}>
        AGREE
      </DarkButton>
      <OutlineButton onClick={onPressDisagree} className='mt-1'>
        DISAGREE
      </OutlineButton>
    </Overlay>
  );
};
