import * as React from 'react';
import { User } from '../../fragments/__generated__/User';
import './UserPhotoCircle.scss';

type PartialProfile = Pick<User, 'firstName' | 'lastName' | 'avatarUrl'>;

interface UserPhotoCircleProps {
  profile: PartialProfile;
  overridingPhotoUrl?: string;
  size: number;
  onClick?();
}

const getInitials = (profile: PartialProfile) => {
  return [profile.firstName, profile.lastName]
    .filter(Boolean)
    .map((x) => x![0])
    .join('');
};

export const UserPhotoCircle: React.FC<UserPhotoCircleProps> = (props) => {
  const { onClick, profile, size, overridingPhotoUrl } = props;
  const photoUrl = overridingPhotoUrl || profile.avatarUrl;

  const dynamicStyle = { width: size, height: size };

  const dynamicInitialStyle = { fontSize: `${Math.round(size * 0.5)}px` };

  if (photoUrl) {
    return (
      <div onClick={onClick} className='user-photo' style={dynamicStyle}>
        <img alt='' className='user-photo__img' src={photoUrl} />
      </div>
    );
  } else {
    const initials = getInitials(profile);
    return (
      <div className='user-photo' style={dynamicStyle}>
        <span style={dynamicInitialStyle} className='user-photo__initials'>
          {initials}
        </span>
      </div>
    );
  }
};
