import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import { CardInputSquareBase } from './CardInputSquare';
import { CardInputProps } from '../../types';
import { useApolloErrorWrapper } from '../../../hooks';
import { SaveSquarePaymentMethod, SaveSquarePaymentMethodVariables } from './__generated__/SaveSquarePaymentMethod';

const MUTATION = gql`
  mutation SaveSquarePaymentMethod($posType: String!, $nonce: String!, $venueId: ID!, $billingZipCode: String!) {
    savePOSPaymentMethod(posType: $posType, nonce: $nonce, venueId: $venueId, billingZipCode: $billingZipCode)
  }
`;

export const CardInputSquare: React.FC<CardInputProps> = (props) => {
  const { venueId } = props;
  const { onError, clearError, error } = useApolloErrorWrapper();

  const [saveSquareCard, { loading: savingSquareCard }] = useMutation<
    SaveSquarePaymentMethod,
    SaveSquarePaymentMethodVariables
  >(MUTATION, {
    onError,
    onCompleted() {
      props.onSave();
    },
  });

  const onSave = React.useCallback(
    ({ nonce, billingZipCode }) => {
      saveSquareCard({
        variables: {
          nonce,
          venueId,
          posType: 'square',
          billingZipCode,
        },
      });
    },
    [venueId, saveSquareCard]
  );

  return (
    <CardInputSquareBase
      saving={savingSquareCard}
      error={error}
      clearError={clearError}
      title={props.title}
      onCancel={props.onCancel}
      onSave={onSave}
    />
  );
};
