import * as React from 'react';
import { SegmentedButton } from './SegmentedButton';
import './SegmentedButtonRow.scss';

type ISegmentedButtonRow = React.FC<{}> & {
  Button: typeof SegmentedButton;
};

export const SegmentedButtonRow: ISegmentedButtonRow = (props) => {
  return (
    <div key='top' className='segmented-button-row'>
      {props.children}
    </div>
  );
};

SegmentedButtonRow.Button = SegmentedButton;
