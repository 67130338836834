import { Option, OptionGroup } from '../types';
import * as React from 'react';
import { CarouselTileButton } from './CarouselTileButton';

interface CarouselOptionListProps {
  optionGroup: OptionGroup;
  selected: Option[];
  onSelect(brand: Option);
}

export const CarouselOptionList: React.FC<CarouselOptionListProps> = (props) => {
  const { optionGroup, onSelect, selected } = props;
  if (optionGroup.options.length === 0) {
    return null;
  }

  const selectedIds = selected.map((o) => o.optionId);

  const els = optionGroup.options.map((option) => (
    <CarouselTileButton
      key={option.optionId}
      option={option}
      onClick={onSelect}
      selected={selectedIds.includes(option.optionId)}
    />
  ));

  return (
    <div className='brand-picker'>
      <h6 className='drink-detail-header-row'>{optionGroup.name}</h6>
      <div className='brand-picker-container'>
        <div className='brand-picker-row'>{els}</div>
      </div>
    </div>
  );
};
