import { ApolloError } from '@apollo/client';
import * as React from 'react';

export const useApolloErrorWrapper = () => {
  const [error, setError] = React.useState<Error | undefined>(undefined);

  const clearError = React.useCallback(() => {
    setError(undefined);
  }, [setError]);

  const onError = React.useCallback(
    (error: ApolloError) => {
      setError(error);
    },
    [setError]
  );

  return { onError, clearError, error };
};
