import { CartItem, Drink, Order, PromoCode, utils, fragments } from '@getnoble/noble-consumer-shared';
import { FirebaseApp } from 'firebase/app';
import { isSupported, getAnalytics, Analytics, setUserId, setUserProperties, logEvent } from 'firebase/analytics';
import { User as Profile } from '../fragments/__generated__/User';

export const getParamsForCart = (cartItem: CartItem) => {
  return {
    quantity: cartItem.quantity,
    item_category: '',
    item_name: cartItem.item.name,
    item_id: cartItem.item.drinkId,
    value: utils.cart.getCartItemTotal(cartItem),
    price: cartItem.item.price,
    currency: 'USD',
  };
};

type AnalyticsCheckoutParams = {
  promoCode?: string;
  venueId: string;
  fulfillmentCenterId: string;
  total?: number;
};

type CostComponentFulfillmentCenter = Pick<fragments.FulfillmentCenterBasicFields, 'id'>;

export const getCheckoutParamsFromModels = (
  venue,
  fuflillmentCenter: CostComponentFulfillmentCenter,
  order: Order,
  orderCalculateResponse: fragments.OrderCalculateFragment | null
) => {
  return getCheckoutParams({
    promoCode: order.promoCode?.code,
    total: orderCalculateResponse?.total,
    venueId: venue.id,
    fulfillmentCenterId: fuflillmentCenter.id,
  });
};

const getCheckoutParams = ({ promoCode, total, venueId, fulfillmentCenterId }: AnalyticsCheckoutParams) => {
  return {
    coupon: promoCode,
    currency: 'USD',
    value: total,
    venueId,
    fulfillmentCenterId,
  };
};

export class AnalyticsService {
  public firebaseApp: FirebaseApp;
  public firebaseAnalytics: Analytics | undefined;
  public sentry;

  constructor(firebaseApp: FirebaseApp, sentry) {
    this.firebaseApp = firebaseApp;
    this.sentry = sentry;
  }

  public async setupGoogleAnalytics() {
    const isGASupported = await isSupported();
    if (isGASupported) {
      this.firebaseAnalytics = getAnalytics(this.firebaseApp);
    }
  }

  public logRegistration() {
    this.logGenericEvent('sign_up', { sign_up_method: 'email' });
  }

  public logLogin(type: string) {
    this.logGenericEvent('login', { sign_up_method: type });
  }

  public setUser(user: Profile) {
    try {
      if (this.firebaseAnalytics) {
        setUserId(this.firebaseAnalytics, user.id);
        setUserProperties(this.firebaseAnalytics, {
          email: user.email,
        });
      }

      this.sentry.setUser({
        email: user.email,
        userID: user.id,
      } as any);
    } catch (err) {}
  }

  public async clearUser() {
    if (this.firebaseAnalytics) {
      setUserId(this.firebaseAnalytics, '');
      setUserProperties(this.firebaseAnalytics, {
        email: null,
      });
    }

    this.sentry.setUser({});
  }

  public recordViewItem(drink: Drink) {
    this.logGenericEvent('view_item', {
      item_id: drink.drinkId,
      item_name: drink.name,
      price: drink.price,
    });
  }

  public recordViewVenue(venue) {
    this.logGenericEvent('view_item_list', {
      venueId: venue.id,
      venueName: venue.name,
    });
  }

  public recordAddCartItem(cartItem: CartItem) {
    const params: any = getParamsForCart(cartItem);

    this.logGenericEvent('add_to_cart', params);

    this.sentry.addBreadcrumb({
      message: 'Item added to cart',
      data: cartItem,
    });
  }

  public recordUpdateCartItem(cartItem: CartItem) {
    const params: any = getParamsForCart(cartItem);

    this.logGenericEvent('update_cart', params);

    this.sentry.addBreadcrumb({
      message: 'Item updated in cart',
      data: cartItem,
    });
  }

  public recordBeginCheckout(
    venue,
    fuflillmentCenter: CostComponentFulfillmentCenter,
    order: Order,
    orderCalculateResponse: fragments.OrderCalculateFragment | null
  ) {
    const params = getCheckoutParamsFromModels(venue, fuflillmentCenter, order, orderCalculateResponse);

    this.logGenericEvent('begin_checkout', params);
    this.sentry.addBreadcrumb({
      message: 'Begin Checkout',
      data: order,
    });
  }

  public recordPurchase(
    venue,
    fuflillmentCenter: CostComponentFulfillmentCenter,
    order: Order,
    orderCalculateResponse: fragments.OrderCalculateFragment | null
  ) {
    const params = getCheckoutParamsFromModels(venue, fuflillmentCenter, order, orderCalculateResponse);
    if (this.firebaseAnalytics) {
      logEvent(this.firebaseAnalytics, 'ecommerce_purchase', params);
    }

    const orderTrackingInfo = {
      ...order,
      paymentMethod: null,
    };

    this.sentry.addBreadcrumb({
      message: 'Purchase',
      data: orderTrackingInfo,
    } as any);
  }

  public recordOrderFinished(params: AnalyticsCheckoutParams) {
    this.logGenericEvent('order_complete', params);
  }

  public selectPromotion(promotion: PromoCode) {
    if (this.firebaseAnalytics) {
      logEvent(this.firebaseAnalytics, 'select_content', {
        promotions: [
          {
            id: promotion.code,
            name: promotion.code,
          },
        ],
      });
    }
  }

  public recordAddPaymentMethod() {
    this.logGenericEvent('add_payment_info', {
      paymentMethodType: 'credit_card',
    });
  }

  public recordScreenView(screenName) {
    if (this.firebaseAnalytics) {
      logEvent(this.firebaseAnalytics, 'screen_view', {
        firebase_screen: screenName,
        firebase_screen_class: screenName,
      });
    }
  }

  public logGenericEvent(eventName, payload) {
    if (this.firebaseAnalytics) {
      logEvent(this.firebaseAnalytics, eventName, payload);
    }
  }
}
