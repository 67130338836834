import * as React from 'react';
import { useCookies } from 'react-cookie';
import { CookieNames } from '../constants';
import { useDispatch } from 'react-redux';
import { auth } from '../services';
import { Actions } from '../constants';

export const signOut = () => {
  return {
    type: Actions.SIGN_OUT,
    payload: null,
  };
};

export const useSignout = () => {
  const dispatch = useDispatch();
  const [_cookies, _setCookie, removeCookie] = useCookies([CookieNames.accessToken]);

  return React.useCallback(async () => {
    await auth.logout();
    dispatch(signOut());
    removeCookie(CookieNames.accessToken, {
      path: '/',
      domain: process.env.NODE_ENV !== 'production' ? undefined : '.getnoble.co',
    });
  }, [dispatch, removeCookie]);
};
