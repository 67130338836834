import { ApolloLink, HttpLink, ApolloClient, ServerParseError, ServerError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { v4 as uuidv4 } from 'uuid';
import { auth } from './services';
import { Logger } from './Logger';

const isServerParseError = (error: Error): error is ServerParseError => {
  return (error as ServerParseError).name === 'ServerParseError';
};

const isServerError = (error: Error): error is ServerError => {
  return (error as ServerError).name === 'ServerError';
};

export const errorLink = onError((args) => {
  try {
    const { operation, graphQLErrors, networkError, response } = args;
    const headers = operation.getContext().headers;
    const traceId = headers ? headers['noble-trace-id'] : undefined;
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          case 'FORBIDDEN':
            auth.logout().then(() => {});
            break;
          case 'HANDLED_API_ERROR':
            Logger.userError({ errorCode: err.extensions?.display?.code || err.message, traceId });
            break;
          default:
            Logger.error(new Error(err.message), {
              isUnhanldedAPIError: true,
              traceId,
            });
        }
      }
    }

    if (networkError) {
      if (isServerParseError(networkError)) {
        Logger.error(
          networkError,
          {
            statusCode: networkError.statusCode,
            bodyText: networkError.bodyText,
            traceId,
          },
          ['networkError', 'ServerParseError', `${networkError.statusCode}`]
        );
      } else if (isServerError(networkError)) {
        Logger.error(
          networkError,
          {
            statusCode: networkError.statusCode,
            traceId,
          },
          ['networkError', 'ServerError', `${networkError.statusCode}`]
        );
      } else {
        Logger.error(networkError, {
          traceId,
          stack: networkError.stack,
        });
      }
    }

    if (response?.errors?.length) {
      response.errors.forEach((err) => {
        if (err?.extensions?.code === 'internal_server_error' && err?.extensions?.display && traceId) {
          err.extensions.display.message = `${err.extensions.display.message} (Service Code: ${traceId})`;
        }
      });
    }
  } catch (err) {
    Logger.error(err as Error);
  }
});

export const createClient = ({ cache, ssrMode, getAccessToken }) => {
  const httpLink = new HttpLink({ uri: '/api' });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${getAccessToken()}`,
        'noble-trace-id': uuidv4(),
      },
    };
  });

  return new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    ssrMode,
  });
};
