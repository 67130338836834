import { utils, OrderStatus } from '@getnoble/noble-consumer-shared';

import { Actions, DefaultState } from '../constants';
import { OrderState } from '../types';
import { stateUtils } from '../utils';

export const orders = (state: OrderState, action): OrderState => {
  const { payload } = action;

  switch (action.type) {
    case Actions.ADD_TO_CART:
      const possibleExistingOrder = stateUtils.getOrderFromOrderState({
        orders: state,
        fulfillmentCenterId: payload.fulfillmentCenter.id,
      });
      if (possibleExistingOrder?.status == OrderStatus.CREATED) {
        return {
          ...state,
          [payload.fulfillmentCenter.id]: {
            order: utils.order.addItemToOrder(possibleExistingOrder, payload.item),
            showBrandSplitModal: false,
          },
        };
      }

      return {
        ...state,
        [payload.fulfillmentCenter.id]: {
          order: utils.order.createDefaultOrder(payload.venue, payload.fulfillmentCenter, payload.item),
          showBrandSplitModal: false,
        },
      };
    case Actions.UPDATE_CART_ITEM: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.replaceItemInOrder(individualOrderState.order, payload.index, payload.item),
        },
      };
    }
    case Actions.REMOVE_CART_ITEM: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.removeItemFromOrder(individualOrderState.order, payload.index),
        },
      };
    }
    case Actions.UPDATE_ORDER_TIP: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.updateTipForOrder(individualOrderState.order, payload.tip),
        },
      };
    }
    case Actions.CHANGE_DESTINATION_TYPE: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.updateDestinationForOrder(individualOrderState.order, payload.destination),
        },
      };
    }
    case Actions.UPDATE_TICKET_ID: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.updateTicketId(individualOrderState.order, payload.ticketId),
        },
      };
    }
    case Actions.UPDATE_ORDER_TIME_OPTION: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.updateTimeSlot(individualOrderState.order, payload.timeSlot),
        },
      };
    }
    case Actions.REMOVE_PROMO_CODE: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.removePromoCodeFromOrder(individualOrderState.order),
        },
      };
    }
    case Actions.SELECT_PAYMENT_METHOD: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];

      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: utils.order.updatePaymentMethodForOrder(individualOrderState.order, payload.paymentMethod),
        },
      };
    }
    case Actions.SELECT_PROMO_CODE: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: {
            ...individualOrderState.order,
            promoCode: action.payload.promoCode,
          },
          showBrandSplitModal: !!action.payload.promoCode.brand,
        },
      };
    }
    case Actions.UPDATE_ORDER_AFTER_SAVE: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: {
            ...individualOrderState.order,
            id: payload.id,
            orderNumber: payload.orderNumber,
            estimatedWaitTime: payload.waitTime,
            status: OrderStatus.PAID,
          },
        },
      };
    }
    case Actions.ACCEPT_DRINK_LIMIT: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          order: individualOrderState?.order ? utils.order.acceptDrinkLimitForOrder(individualOrderState.order) : null,
        },
      };
    }
    case Actions.CLEAR_ORDER: {
      const { fulfillmentCenterId } = payload;
      return {
        ...state,
        [fulfillmentCenterId]: undefined,
      };
    }
    case Actions.HIDE_BRAND_MODAL: {
      const { fulfillmentCenterId } = payload;
      const individualOrderState = state[fulfillmentCenterId];
      return {
        ...state,
        [fulfillmentCenterId]: {
          ...individualOrderState,
          showBrandSplitModal: false,
        },
      };
    }
    case Actions.SET_ORDER: {
      const { fulfillmentCenterId } = payload;
      return {
        ...state,
        [fulfillmentCenterId]: {
          order: payload.order,
          showBrandSplitModal: false,
          preorderId: payload.preorderId,
        },
      };
    }
    case Actions.SIGN_OUT:
    case Actions.CLEAR_ALL_ORDERS:
      return DefaultState.orders;
    default:
      return state || DefaultState.orders;
  }
};
