import classnames from 'classnames';
import * as React from 'react';
import { commonUtils } from '../../utils';
import './Layout.scss';

interface LayoutProps {
  hasNavbar?: boolean;
  title?: string;
  className?: string;
  children?: React.ReactNode;
  backgroundColorVariant?: 'dark' | 'dark-gray' | 'lighter-dark';
}

export const Layout = React.forwardRef<HTMLDivElement, LayoutProps>((props, ref) => {
  const { children, hasNavbar, title, className, backgroundColorVariant } = props;
  React.useEffect(() => {
    if (title) {
      commonUtils.setTitle(title);
    }
  }, [title]);

  React.useEffect(() => {
    const className = `noble-screen--${backgroundColorVariant ?? 'dark'}`;
    document.querySelector('body')!.classList.add(className);
    return () => {
      document.querySelector('body')!.classList.remove(className);
    };
  }, [backgroundColorVariant]);

  return (
    <div ref={ref} className={classnames(className, 'layout', { 'layout--navbar': hasNavbar !== false })}>
      {children}
    </div>
  );
});
