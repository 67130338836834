import { Feature, CartItem, utils, Order } from '@getnoble/noble-consumer-shared';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from '../../components';
import { GenericButton } from '../../components/GenericButton';
import { CarouselOptionList } from './CarouselOptionList';
import { Comments } from './Comments';
import './DrinkDetail.scss';
import { RadioMenuList } from './RadioMenuList';
import { analytics } from '../../services';
import { DrinkDetailMode, Option } from './types';
import { DescriptionRow } from './DescriptionRow';
import { OptionGroupWidget } from './../../globalTypes';
import { DrinkLimitOverlay } from '../DrinkLimitOverlay';
import { getCSSPropsForBottomButton } from '../../utils/menuStyle';
import { MenuStyle, DrinkDetailData } from '../../menu/types';

interface DrinkProps {
  mode: DrinkDetailMode;
  backUrl: string;
  cartItem: CartItem;
  data: DrinkDetailData;
  orderInProgress: boolean;
  addToCart(item: CartItem);
  acceptDrinkLimit();
  order?: Order;
  menuStyle: MenuStyle;
}

interface DrinkState {
  cartItem: CartItem;
  showDrinkLimitOverlay: boolean;
}

export class DrinkDetail extends React.Component<DrinkProps, DrinkState> {
  public static Mode = DrinkDetailMode;

  public onClickQuantityPlus;
  public onClickQuantityMinus;

  constructor(props: DrinkProps) {
    super(props);

    this.state = { cartItem: props.cartItem, showDrinkLimitOverlay: false };

    this.onClickQuantityPlus = this.changeQuantity.bind(this, 1);
    this.onClickQuantityMinus = this.changeQuantity.bind(this, -1);
  }

  componentDidMount() {
    analytics.recordViewItem(this.props.data.menuItem);
  }

  public onPressAddToCartButton = () => {
    const { venue } = this.props.data;
    if (utils.venue.shouldShowDrinkLimitOverlay(venue, this.props.order ?? null, this.state.cartItem)) {
      this.setState({ showDrinkLimitOverlay: true });
    } else {
      this.props.addToCart(this.state.cartItem);
    }
  };

  public onPressDrinkLimitAgree = () => {
    this.props.acceptDrinkLimit();
    this.props.addToCart(this.state.cartItem);
  };

  public onPressDrinkLimitDisagree = () => {
    this.setState({ showDrinkLimitOverlay: false });
  };

  public changeQuantity(delta) {
    const quantity = Math.max(this.state.cartItem.quantity + delta, this.props.mode === DrinkDetailMode.add ? 1 : 0);
    this.setState({
      cartItem: {
        ...this.state.cartItem,
        quantity,
      },
    });
  }

  public onChangeComments = (comments) => {
    this.setState({
      cartItem: {
        ...this.state.cartItem,
        comments,
      },
    });
  };

  public onChangeOption = (option: Option) => {
    const options = utils.cart.toggleOption({
      item: this.props.data.menuItem,
      selectedOptions: this.state.cartItem.options,
      option,
    });

    this.setState({
      cartItem: {
        ...this.state.cartItem,
        options,
      },
    });
  };

  public renderOptionPickerRows = () => {
    const { cartItem } = this.state;
    const carouselGroups = this.props.data.menuItem.optionGroups.filter((optionGroup) => {
      return optionGroup.widget !== OptionGroupWidget.CAROUSEL;
    });

    return carouselGroups.map((optionGroup) => {
      return (
        <RadioMenuList
          key={optionGroup.id}
          optionGroup={optionGroup}
          selected={cartItem.options}
          onSelect={this.onChangeOption}
        />
      );
    });
  };

  public renderCarouselOptionGroups() {
    const { cartItem } = this.state;
    const carouselGroups = this.props.data.menuItem.optionGroups.filter((optionGroup) => {
      return optionGroup.widget === OptionGroupWidget.CAROUSEL;
    });

    return carouselGroups.map((optionGroup) => {
      return (
        <CarouselOptionList
          key={optionGroup.id}
          optionGroup={optionGroup}
          selected={cartItem.options}
          onSelect={this.onChangeOption}
        />
      );
    });
  }

  public render() {
    const { backUrl, mode, menuStyle, data } = this.props;
    const { menuItem, venue } = data;

    const style = {
      backgroundImage: `url(${menuItem.imageUrl})`,
    };

    const { cartItem } = this.state;

    let addCartButton;
    const cartButtonStyle = getCSSPropsForBottomButton(menuStyle);
    if (!venue.features.includes(Feature.menuOnly)) {
      if (menuItem.soldOut) {
        addCartButton = (
          <GenericButton style={cartButtonStyle} disabled={true} className='bottom-button'>
            SOLD OUT
          </GenericButton>
        );
      } else if (this.props.orderInProgress && sessionStorage.getItem('orderInProgress') == 'true') {
        addCartButton = (
          <GenericButton style={cartButtonStyle} disabled={true} className='bottom-button'>
            ORDER IN PROGRESS
          </GenericButton>
        );
      } else {
        addCartButton = (
          <GenericButton
            style={cartButtonStyle}
            onClick={this.onPressAddToCartButton}
            type='submit'
            className='bottom-button'
          >
            {mode === DrinkDetailMode.add ? 'ADD TO CART' : 'UPDATE'}
            <span className='add-to-cart-price'>
              {utils.money.formatAsPrice(utils.cart.getCartItemTotal(cartItem))}
            </span>
          </GenericButton>
        );
      }
    }

    return (
      <Layout hasNavbar={false} title={menuItem.name} className='drink-detail' backgroundColorVariant='dark'>
        <div className='drink-detail__image' style={style}>
          <Link to={backUrl}>
            <img alt='' src='/img/drink-detail/chevron-stroke.png' className='drink-detail__back-img' />
          </Link>
        </div>
        <section className='drink-detail__content'>
          <div className='drink-detail__header'>
            <h6 className='drink-detail__name'>{menuItem.name}</h6>
            <span className='drink-detail__price'>${menuItem.price}</span>
          </div>
          <div className='drink-detail__quantity'>
            Select a quantity
            <div className='drink-detail__quantity-controls'>
              <button className='drink-detail__quantity-btn' onClick={this.onClickQuantityMinus}>
                <img alt='Minus Qunatity' src='/img/drink-detail/minus.png' />
              </button>
              <span className='drink-detail__quantity-amount'>{cartItem.quantity}</span>
              <button className='drink-detail__quantity-btn' onClick={this.onClickQuantityPlus}>
                <img alt='Plus Qunatity' src='/img/drink-detail/plus.png' />
              </button>
            </div>
          </div>
          {this.renderCarouselOptionGroups()}
          <DescriptionRow item={menuItem} />
          {this.renderOptionPickerRows()}
          <Comments
            disableComments={venue.config.disableCommentsOnMenuItems}
            value={cartItem.comments}
            onChange={this.onChangeComments}
          />
        </section>
        {addCartButton}
        <DrinkLimitOverlay
          drinkLimit={venue.config.drinkUnitLimitPerPerson!}
          open={this.state.showDrinkLimitOverlay}
          onPressAgree={this.onPressDrinkLimitAgree}
          onPressDisagree={this.onPressDrinkLimitDisagree}
        />
      </Layout>
    );
  }
}
