import { CreditCardType, utils } from '@getnoble/noble-consumer-shared';

export const getImageForCard = (cardType?: CreditCardType) => {
  switch (cardType) {
    case CreditCardType.visa:
      return '/img/payments/cards/visa.png';
    case CreditCardType.masterCard:
      return '/img/payments/cards/master-card.png';
    case CreditCardType.americanExpress:
      return '/img/payments/cards/amex.png';
    case CreditCardType.discover:
      return '/img/payments/cards/discover.png';
    default:
      return '/img/payments/cards/generic.png';
  }
};

export const getImageForCardNumber = (cardNumber) => getImageForCard(utils.payments.getCardTypeFromNumber(cardNumber));
