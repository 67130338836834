import classnames from 'classnames';
import { utils } from '@getnoble/noble-consumer-shared';
import { Option } from '../types';
import * as React from 'react';

interface CarouselTileButtonProps {
  option: Option;
  selected: boolean;
  onClick: (brand: Option) => null;
}

const alignments = ['left', 'center', 'right'];

export const CarouselTileButton: React.FC<CarouselTileButtonProps> = ({ onClick, selected, option }) => {
  const onClickWrapped = React.useCallback(() => {
    onClick(option);
  }, [onClick, option]);

  const customOptions = option.options;

  let content;
  if (option.imageUrl && !customOptions.hideImage) {
    content = <img alt='' src={option.imageUrl} />;
  } else {
    content = <span>{option.name}</span>;
  }

  let bottomText;
  if (customOptions.customTitle) {
    bottomText = customOptions.customTitle;
  } else {
    bottomText = `+ ${utils.money.formatAsPrice(option.price)}`;
  }

  const bottomClass = customOptions.customAlignment
    ? classnames({
        center: alignments[customOptions.customAlignment] !== null,
      })
    : '';

  const selectedLayer = selected ? (
    <div className='brand-button__selected-layer'>
      <img alt='Selected' src='/img/drink-detail/checkmark.png' />
    </div>
  ) : null;

  return (
    <div className='brand-button' onClick={onClickWrapped}>
      {selectedLayer}
      <div className='brand-button__content'>{content}</div>
      <span className={bottomClass}>{bottomText}</span>
    </div>
  );
};
