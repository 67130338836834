import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAccessToken } from '../hooks';
const jwtDecode = require('jwt-decode');

const isAdmin = (accessToken: string) => {
  try {
    const { permissions } = jwtDecode(accessToken);
    return permissions.includes('super-admin');
  } catch {
    return false;
  }
};

export const AdminRoute: React.FC<RouteProps> = (props) => {
  const accessToken = useAccessToken();

  const { component: Component, ...rest } = props;

  const renderRoute = React.useCallback(
    (routeProps) => {
      if (accessToken && isAdmin(accessToken)) {
        // @ts-expect-error
        return <Component {...routeProps} />;
      } else {
        return <Redirect to='/' />;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken, Component]
  );

  return <Route {...rest} render={renderRoute} />;
};
