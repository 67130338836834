import { path } from 'static-path';

export const accountInfo = path(`/venue/:venueId/account`);
export const editAccount = path(`/venue/:venueId/account/edit`);

export const about = path(`/venue/:venueId/about`);
// export const addPaymentMethodsMaintenance = path(`/venue/:venueId/payment-methods/add/:paymentMethodType`)
// export const addPromoMethodsMaintenance = path(`/venue/:venueId/promo-codes/add`)
// export const deliveryInput = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/delivery-input/:orderId`)
// export const drink = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/menu/:categoryId/item/:itemId`)
// export const onboarding = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/onboarding`)
// export const onboardingEmailLogin = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/onboarding/login`)
// export const onboardingForgotPassword = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/onboarding/forgot-password`)
// export const onboardingProfileSetup = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/onboarding/profile`)
// export const onboardingVerifyPhoneNumber = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/onboarding/phone`)
// export const onboardingVerifyPhoneNumberPasswordless = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/onboarding/phone-passwordless`)
// export const orderAddCreditCard = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/payment-methods/add/:paymentMethodType`)
// export const orderAddPromoMethods = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/promo-codes/add`)
// export const orderConfirmation = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/confirmation/:orderId`)
// export const orderHistory = path(`/venue/:venueId/order-history`)
// export const orderMap = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/:orderId/map`)
// export const orderPaymentMethods = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/payment-methods/:paymentMethodType`)
// export const orderPromoMethods = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/promo-codes`)
// export const orderRating = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order-rating/:orderId`)
// export const orderRatingEmail = path(`/venue/:venueId/order-rating-external/:ratingUrl`)
// export const orderReenterPaymentMethods = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/payment-methods/:paymentMethodType/reenter`)
// export const orderRejected = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/rejected/:orderId`)
// export const orderStatus = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/status/:orderId`)
// export const orderSummary = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order`)
// export const orderUpdateDrinkDetail = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/cart/:index`)
// export const pastOrderDetail = path(`/venue/:venueId/past-order/:orderId`)
// export const paymentMethodsMaintenance = path(`/venue/:venueId/payment-methods/:paymentMethodType`)
// export const preOrderConfirmation = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/order/preorder-confirmation/:orderId`)
// export const preOrderDetail = path(`/venue/:venueId/pre-order/:orderId`)
// export const promoMethodsMaintenance = path(`/venue/:venueId/promo-codes`)
// export const register = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/onboarding/register`)

export const venueCustomHome = path(`/:slug`);
export const venueHomeUrl = path(`/venue/:venueId`);

export const venueWelcome = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/welcome`);

export const menuUrl = path(`/venue/:venueId/fulfillment-center/:fulfillmentCenterId/menu`);

export const drinkUrl = ({ venueId, fulfillmentCenterId, categoryId, itemId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/menu/${categoryId}/item/${itemId}`;
};

export const orderSummaryUrl = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order`;
};

export const orderRejectedUrl = ({ venueId, fulfillmentCenterId, orderId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/rejected/${orderId}`;
};

export const orderPaymentMethodsUrl = ({ venueId, fulfillmentCenterId, paymentMethodType }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/payment-methods/${paymentMethodType}`;
};

export const orderReenterPaymentMethodsUrl = ({ venueId, fulfillmentCenterId, paymentMethodType }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/payment-methods/${paymentMethodType}/reenter`;
};

export const orderAddCreditCardUrl = ({ venueId, fulfillmentCenterId, paymentMethodType }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/payment-methods/add/${paymentMethodType}`;
};

export const orderPromoMethodsUrl = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/promo-codes`;
};

export const orderAddPromoMethodsUrl = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/promo-codes/add`;
};

export const orderStatusUrl = ({ venueId, fulfillmentCenterId, orderId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/status/${orderId}`;
};

export const orderConfirmationUrl = ({ venueId, fulfillmentCenterId, orderId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/confirmation/${orderId}`;
};

export const deliveryInputUrl = ({ venueId, fulfillmentCenterId, orderId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/delivery-input/${orderId}`;
};

export const preOrderConfirmationUrl = ({ venueId, fulfillmentCenterId, orderId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/preorder-confirmation/${orderId}`;
};

export const orderMapUrl = ({ venueId, fulfillmentCenterId, orderId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/${orderId}/map`;
};

export const orderUpdateDrinkDetailUrl = ({ venueId, fulfillmentCenterId, index }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order/cart/${index}`;
};

export const paymentMethodsMaintenanceUrl = ({ venueId, paymentMethodType }) => {
  return `/venue/${venueId}/payment-methods/${paymentMethodType}`;
};

export const addPaymentMethodsMaintenanceUrl = ({ venueId, paymentMethodType }) => {
  return `/venue/${venueId}/payment-methods/add/${paymentMethodType}`;
};

export const promoMethodsMaintenanceUrl = ({ venueId }) => {
  return `/venue/${venueId}/promo-codes`;
};

export const addPromoMethodsMaintenanceUrl = ({ venueId }) => {
  return `/venue/${venueId}/promo-codes/add`;
};

export const orderHistoryUrl = ({ venueId }) => {
  return `/venue/${venueId}/order-history`;
};

export const pastOrderDetailUrl = ({ venueId, orderId }) => {
  return `/venue/${venueId}/past-order/${orderId}`;
};

export const preOrderDetailUrl = ({ venueId, orderId }) => {
  return `/venue/${venueId}/pre-order/${orderId}`;
};

export const onboardingUrl = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/onboarding`;
};

export const onboardingForgotPasswordUrl = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/onboarding/forgot-password`;
};

export const registerUrl = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/onboarding/register`;
};

export const onboardingVerifyPhoneNumber = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/onboarding/phone`;
};

export const onboardingVerifyPhoneNumberPasswordless = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/onboarding/phone-passwordless`;
};

export const onboardingEmailLogin = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/onboarding/login`;
};

export const onboardingProfileSetup = ({ venueId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/onboarding/profile`;
};

export const supportUrl = path(`/venue/:venueId/support`);
export const supportConfirmationUrl = path(`/venue/:venueId/support/confirmation`);

export const orderRatingUrl = ({ venueId, orderId, fulfillmentCenterId }) => {
  return `/venue/${venueId}/fulfillment-center/${fulfillmentCenterId}/order-rating/${orderId}`;
};

export const orderRatingEmailUrl = ({ venueId, ratingUrl }) => {
  return `/venue/${venueId}/order-rating-external/${ratingUrl}`;
};

export const createPlaceholderUrl = (urlFunc: (params) => string, params: string[]) => {
  const paramMap = params.reduce((accum, param) => {
    accum[param] = `:${param}`;

    return accum;
  }, {});

  return urlFunc(paramMap);
};
