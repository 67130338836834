import * as React from 'react';
import './Overlay.scss';

interface OverlayProps {
  open: boolean;
}

export const Overlay: React.FC<OverlayProps> = (props) => {
  if (!props.open) {
    return null;
  }

  return (
    <div className='overlay'>
      <div className='overlay-content'>{props.children}</div>
    </div>
  );
};
