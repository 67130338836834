import { FirebaseApp } from 'firebase/app';
import { Database, DatabaseReference, getDatabase, ref } from 'firebase/database';

export class RealtimeDatabase {
  public firebaseDatabase: Database;

  constructor(firebaseApp: FirebaseApp) {
    this.firebaseDatabase = getDatabase(firebaseApp);
  }

  public ref(refPath: string): DatabaseReference {
    return ref(this.firebaseDatabase, refPath);
  }

  // public
}
