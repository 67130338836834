import classnames from 'classnames';
import * as React from 'react';

interface SegmentedButtonProps {
  title: string;
  selected: boolean;
  onClick: () => void;
  testId?: string;
}

export const SegmentedButton: React.FC<SegmentedButtonProps> = (props) => {
  const { onClick, title, selected, testId } = props;
  const className = classnames('btn', 'segmented-button', { 'segmented-button-selected': selected });

  return (
    <button data-segmented-btn-key={testId} onClick={onClick} className={className}>
      {title}
    </button>
  );
};
