import * as React from 'react';
import { CartItem, Order } from '@getnoble/noble-consumer-shared';
import { DrinkDetail as DrinkDetailWithItem } from './DrinkDetail';
export { DrinkDetailMode } from './types';
import { Loading, ErrorOverlay } from '../../components';
import { DrinkDetailMode } from './types';
import { MenuStyle, DrinkDetailData } from '../../menu/types';
import { DEFAULT_STYLE } from '../../menu/Menu/constants';

interface DrinkProps {
  mode: DrinkDetailMode;
  backUrl: string;
  item: CartItem | null;
  data?: DrinkDetailData;
  loading: boolean;
  error?: Error;
  orderInProgress: boolean;
  addToCart(item: CartItem);
  acceptDrinkLimit();
  order?: Order;
  menuStyle?: MenuStyle | null;
}

export const DrinkDetail = (props: DrinkProps) => {
  if (props.loading || !props.data || !props.item || props.error) {
    return (
      <>
        <Loading />
        <ErrorOverlay href={props.backUrl} error={props.error} />
      </>
    );
  }

  const menuStyle = props.menuStyle ?? DEFAULT_STYLE;

  return (
    <DrinkDetailWithItem
      mode={props.mode}
      backUrl={props.backUrl}
      cartItem={props.item}
      data={props.data}
      orderInProgress={props.orderInProgress}
      addToCart={props.addToCart}
      order={props.order}
      acceptDrinkLimit={props.acceptDrinkLimit}
      menuStyle={menuStyle}
    />
  );
};
