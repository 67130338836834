import * as React from 'react';
import { Link } from 'react-router-dom';
import * as types from '../../types';

const getImageUrlForType = (type: types.NavbarButtonType): string => {
  switch (type) {
    case types.NavbarButtonType.chevron:
      return '/img/navbar/chevron.png';
    case types.NavbarButtonType.cross:
      return '/img/navbar/cross.png';
    case types.NavbarButtonType.hamburger:
      return '/img/navbar/menu/menu.png';
    default:
      return '';
  }
};

interface NavbarButtonProps {
  type: types.NavbarButtonType;
  onClick?: () => void;
  to?: string;
}

export class NavbarButton extends React.Component<NavbarButtonProps, {}> {
  public render() {
    const content = <img alt='' src={getImageUrlForType(this.props.type)} />;

    if (this.props.onClick) {
      return (
        <div className='navbar-button' onClick={this.props.onClick}>
          {content}
        </div>
      );
    } else {
      return (
        <Link className='navbar-button' to={this.props.to!}>
          {content}
        </Link>
      );
    }
  }
}
