import * as React from 'react';
import { Loading, ErrorOverlay, DarkButton, Layout, Navbar } from '../../../components';
import { payments } from '@square/web-sdk';
import type { Payments, Card } from '@square/web-sdk';
import { ErrorList } from '../ErrorList';
import * as types from '../../../types';
import appConfig from '../../../config';
import { Logger } from '../../../Logger';

interface CardInputSquareBaseProps {
  onSave({ nonce, billingZipCode }: { nonce: string; billingZipCode: string });
  onCancel();
  title: string;
  error?: Error;
  clearError();
  saving: boolean;
}

async function initializeCard(payments: Payments) {
  const card = await payments.card({
    style: {
      '.input-container': {
        borderColor: 'transparent',
        borderRadius: '0',
      },
      '.input-container.is-focus': {
        borderColor: 'transparent',
      },
      '.input-container.is-error': {
        borderColor: 'transparent',
      },
      '.message-text': {
        color: '#999999',
      },
      '.message-icon': {
        color: '#999999',
      },
      '.message-text.is-error': {
        color: '#ff1600',
      },
      '.message-icon.is-error': {
        color: '#ff1600',
      },
      input: {
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        fontFamily: 'helvetica neue, sans-serif',
        fontSize: '16px',
      },
      'input::placeholder': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
      'input.is-error': {
        color: '#ff1600',
      },
    },
  });
  await card.attach('#card-container');
  return card;
}

export const CardInputSquareBase: React.FC<CardInputSquareBaseProps> = (props) => {
  const [errors, setErrors] = React.useState<null | string[]>(null);
  const cardRef = React.useRef<Card | null>();
  const [loading, setLoading] = React.useState(false);
  const { onSave } = props;

  React.useEffect(() => {
    const start = async () => {
      try {
        const paymentsInst = await payments(appConfig.SQUARE_APP_ID);
        cardRef.current = await initializeCard(paymentsInst!);
      } catch (e) {
        Logger.error(e);
        setErrors([e.message]);
      }
    };

    start();
  }, [onSave, setErrors]);

  const requestCardNonce = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (cardRef.current) {
        setLoading(true);
        try {
          const tokenResult = await cardRef.current.tokenize();
          setLoading(false);
          if (tokenResult.status === 'OK') {
            onSave({
              nonce: tokenResult.token!,
              billingZipCode: tokenResult.details!.billing!.postalCode!,
            });
          } else if (tokenResult.errors) {
            setErrors(tokenResult.errors.map((e) => e.message));
          }
        } catch (error) {
          Logger.error(error);
          setErrors([error.message]);
          setLoading(false);
        }
      }
    },
    [onSave]
  );

  return (
    <Layout title={props.title}>
      <Navbar>
        <Navbar.Button onClick={props.onCancel} type={types.NavbarButtonType.chevron} />
        <Navbar.Title>{props.title}</Navbar.Title>
      </Navbar>
      <form onSubmit={requestCardNonce}>
        <form id='payment-form'>
          <div id='card-container'></div>
        </form>
        <ErrorList errors={errors} />
        <DarkButton disabled={loading} className='bottom-button' type='submit'>
          SAVE
        </DarkButton>
        <ErrorOverlay error={props.error} onClickButton={props.clearError} />
        {props.saving && <Loading />}
      </form>
    </Layout>
  );
};
