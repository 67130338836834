import classnames from 'classnames';
import * as React from 'react';
import './Navbar.scss';
import { NavbarButton } from './NavbarButton';
import { NavbarTextButton } from './NavbarTextButton';
import { NavbarTitle } from './NavbarTitle';

interface NavbarProps {
  color: 'dark' | 'clear';
}

const CLASS_FOR_COLOR = {
  dark: 'navbar--dark',
  clear: 'navbar--clear',
};

export class Navbar extends React.Component<NavbarProps, {}> {
  public static Title = NavbarTitle;
  public static Button = NavbarButton;
  public static TextButton = NavbarTextButton;

  public static defaultProps = {
    color: 'dark',
  };

  public render() {
    return <div className={classnames('navbar', CLASS_FOR_COLOR[this.props.color])}>{this.props.children}</div>;
  }
}
