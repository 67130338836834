import { PaymentMethodType } from '@getnoble/noble-consumer-shared';
import * as React from 'react';
import { AddPaymentMethodButton } from './AddPaymentMethodButton';
import './AddPaymentMethods.scss';

interface AddPaymentMethodsProps {
  onClick: (type: PaymentMethodType) => void;
  className?: string;
  isApplePayEnabled: boolean;
}

export const AddPaymentMethods: React.FC<AddPaymentMethodsProps> = ({ onClick, className, isApplePayEnabled }) => {
  const onClickAddCreditCard = React.useCallback(() => {
    onClick(PaymentMethodType.Card);
  }, [onClick]);

  const onClickApplePay = React.useCallback(() => {
    onClick(PaymentMethodType.ApplePay);
  }, [onClick]);

  const applePayEnabled =
    process.env.NODE_ENV !== 'development' &&
    (window as any).ApplePaySession &&
    (window as any).ApplePaySession.supportsVersion(3) &&
    (window as any).ApplePaySession.canMakePayments();

  return (
    <div className={className}>
      <AddPaymentMethodButton testId='payment-list-add-btn' showPlus={true} onClick={onClickAddCreditCard}>
        ADD CREDIT CARD
      </AddPaymentMethodButton>
      {isApplePayEnabled && applePayEnabled && (
        <AddPaymentMethodButton showPlus={false} onClick={onClickApplePay}>
          <img className='add-payment-method-apple-pay' src='/img/payments/marks/ApplePay.png' />
          Apple Pay
        </AddPaymentMethodButton>
      )}
    </div>
  );
};
