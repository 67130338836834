import { Order, PromoCode, PaymentMethod } from '@getnoble/noble-consumer-shared';
import { Actions } from './constants';
import { analytics } from './services';

export const setOrder = (order: Order, venueId: string, fulfillmentCenterId: string, preorderId?) => {
  return {
    type: Actions.SET_ORDER,
    payload: {
      order,
      venueId,
      fulfillmentCenterId,
      preorderId,
    },
  };
};

export const setFromForOnboarding = ({
  from,
  venueId,
  fulfillmentCenterId,
}: {
  from: string;
  venueId: string;
  fulfillmentCenterId?: string;
}) => {
  return {
    type: Actions.ONBOARDING_START,
    payload: {
      from,
      venueId,
      fulfillmentCenterId,
    },
  };
};

export const selectPaymentMethod = ({
  paymentMethod,
  fulfillmentCenterId,
}: {
  paymentMethod: PaymentMethod;
  fulfillmentCenterId: string;
}) => {
  return {
    type: Actions.SELECT_PAYMENT_METHOD,
    payload: { paymentMethod, fulfillmentCenterId },
  };
};

export const selectPromoCode = ({
  promoCode,
  fulfillmentCenterId,
}: {
  promoCode: PromoCode;
  fulfillmentCenterId: string;
}) => {
  analytics.selectPromotion(promoCode);
  return {
    type: Actions.SELECT_PROMO_CODE,
    payload: { promoCode, fulfillmentCenterId },
  };
};
