import classnames from 'classnames';
import * as React from 'react';
import InputMask from 'react-input-mask';
import './TextInput.scss';

interface TextInputProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: (e) => void;
  placeholder?: string;
  className?: string;
  type?: string;
  autoCorrect?: boolean;
  autoCapitalize?: boolean;
  multiline?: boolean;
  innerRef?: any;
  style?: React.CSSProperties;
  mask?: string;
  maskChar?: string;
  centered?: boolean;
  id?: string;
  hasError?: boolean;
  inputMode?: string;
  maxLength?: number;
  disabled?: boolean;
  name?: string;
}

class TextInputUnwrapped extends React.Component<TextInputProps, {}> {
  public static defaultProps = {
    type: 'input',
    autoCorrect: true,
    autoCapitalize: true,
    multiline: false,
    centered: true,
  };

  public onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.props.onChange(value);
  };

  public render() {
    const {
      centered,
      value,
      placeholder,
      type,
      mask,
      maskChar,
      multiline,
      innerRef,
      style,
      id,
      hasError,
      onFocus,
      onBlur,
      onClick,
      inputMode,
      maxLength,
      disabled,
      name,
    } = this.props;

    const className = classnames(
      'noble-input',
      {
        'noble-input--centered': centered,
        'noble-input--error': hasError,
      },
      this.props.className
    );

    const Tag: any = mask ? InputMask : multiline ? 'textarea' : 'input';
    const props: any = {
      placeholder,
      type,
      value,
      onChange: this.onChange,
      className,
      ref: innerRef,
      style,
      id,
      onFocus,
      onBlur,
      onClick,
      inputMode,
      maxLength,
      disabled,
      name,
    };

    if (mask) {
      props.mask = mask;
      props.maskChar = maskChar;
    }

    return <Tag {...props} />;
  }
}

export const TextInput = React.forwardRef((props: TextInputProps, ref) => (
  <TextInputUnwrapped {...props} innerRef={ref} />
));
