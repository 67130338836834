/* tslint:disable no-console */
import * as Sentry from '@sentry/browser';
import config from './config';

const time = () => new Date().toISOString().substring(0, 23);

const generic = (messages: any[], func: string, name: string) => {
  let args = [`${time()} - ${name}:`];
  try {
    const str = JSON.stringify(messages);
    args.push(str);
  } catch (err) {
    args = args.concat(messages);
  }

  // eslint-disable-next-line no-console
  console[func].apply(console, args);
};

export class Logger {
  public static info(...messages: any[]) {
    generic(messages, 'info', 'INFO');
  }

  public static warn(...messages: any[]) {
    generic(messages, 'warn', 'WARNING');
  }

  public static error(error: Error, extra?: any, fingerPrint?: string[]) {
    generic([error.message], 'error', 'ERROR');
    if (config.SEND_EVENTS) {
      Sentry.captureException(error, (scope) => {
        if (extra) {
          scope.setContext('EXTRA INFO', extra);
        }

        if (fingerPrint) {
          scope.setFingerprint(fingerPrint);
        }

        scope.setTag('noble_type', 'internal_error');

        return scope;
      });
    }
  }

  public static userError({ errorCode, traceId }: { errorCode: string; traceId: string }) {
    if (config.SEND_EVENTS) {
      Sentry.captureException(new Error(errorCode), (scope) => {
        scope.setFingerprint([errorCode]);
        scope.setContext('Tracing', {
          traceId,
        });

        scope.setTag('noble_type', 'user_error');

        return scope;
      });
    }
  }
}

export default Logger;
