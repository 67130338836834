import * as Sentry from '@sentry/browser';
import { initializeApp } from 'firebase/app';
import config from '../config';
import { AuthService } from './auth';
import { AnalyticsService } from './analytics';
import { RealtimeDatabase } from './realtimeDatabase';

const firebaseApp = initializeApp(config.FIREBASE_CONFIG);

export const auth = new AuthService(firebaseApp);
export const analytics = new AnalyticsService(firebaseApp, Sentry);
export const realtimeDatabase = new RealtimeDatabase(firebaseApp);
