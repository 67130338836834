/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum IdentityProvider {
  APPLE = "APPLE",
  AUTH0 = "AUTH0",
  FACEBOOK = "FACEBOOK",
  SMS = "SMS",
}

export enum OptionGroupWidget {
  CAROUSEL = "CAROUSEL",
  RADIO = "RADIO",
}

export enum OrderDevice {
  android = "android",
  ios = "ios",
  web = "web",
}

export enum OrderStatus {
  AwaitingPickup = "AwaitingPickup",
  CancelledPreorder = "CancelledPreorder",
  Created = "Created",
  Failed = "Failed",
  Fulfilled = "Fulfilled",
  Paid = "Paid",
  PartialRefund = "PartialRefund",
  Preorder = "Preorder",
  Refunded = "Refunded",
  Rejected = "Rejected",
  Scheduled = "Scheduled",
}

export enum OrderType {
  PreOrder = "PreOrder",
  Realtime = "Realtime",
  Scheduled = "Scheduled",
}

export enum RewardLevel {
  ITEM = "ITEM",
  ORDER = "ORDER",
}

export enum TipType {
  Amount = "Amount",
  None = "None",
  Percent = "Percent",
}

export interface CartInput {
  comments?: string | null;
  drinkId: number;
  drinkOptions: number[];
  quantity: number;
}

export interface ConfirmOrderInput {
  payment?: PaymentSource | null;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface DeliveryLocationInput {
  key: string;
  value: string;
}

export interface FreedomPayApmHpcData {
  paymentKey: string;
  sessionKey: string;
}

export interface LoyaltyRewardInput {
  name: string;
  value: string;
  externalId: string;
  integrationMetadata: string;
  level: RewardLevel;
  reward?: any | null;
}

export interface OrderBillingInfo {
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zipCode: string;
}

export interface OrderFeedbackRatingInput {
  questionId: string;
  rating: number;
}

export interface OrderInput {
  delivery?: DeliveryLocationInput[] | null;
  cart: CartInput[];
  promoCode?: string | null;
  timeSlot?: string | null;
  timeSlotId?: string | null;
  amount?: number | null;
  tip?: number | null;
  tipPercentage?: number | null;
  foodTax?: number | null;
  alcoholTax?: number | null;
  salesTax?: number | null;
  total?: number | null;
  firebaseToken?: string | null;
  device: OrderDevice;
  fulfillmentCenterId: string;
  scheduledTime?: string | null;
  type: OrderType;
  pickupLocationId?: string | null;
  paymentToken?: string | null;
  freedomPayAppleToken?: string | null;
  freedomPayGoogleToken?: string | null;
  braintreePaymentNonce?: string | null;
  payment?: PaymentSource | null;
  deviceData?: string | null;
  billingInfo?: OrderBillingInfo | null;
  idempotencyKey?: string | null;
  ticketId?: string | null;
  paymentMethodId?: string | null;
  rewards?: LoyaltyRewardInput[] | null;
}

export interface PaymentSource {
  paymentToken?: string | null;
  freedomPayAppleToken?: string | null;
  freedomPayGoogleToken?: string | null;
  freedomPayApmHpcData?: FreedomPayApmHpcData | null;
  braintreePaymentNonce?: string | null;
  storedPaymentMethodId?: string | null;
}

export interface SupportMessage {
  buildNumber: string;
  email?: string | null;
  name?: string | null;
  message: string;
  subject: string;
  version: string;
}

export interface TimeSlotDayInput {
  year: number;
  month: number;
  day: number;
}

export interface UserInfo {
  avatarUrl?: string | null;
  email?: string | null;
  firebaseToken?: string | null;
  password?: string | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  phone?: string | null;
  dateOfBirth?: string | null;
  updateAuth0?: boolean | null;
  tosVersion?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
