import { gql } from '@apollo/client';
import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { SavePaymentMethod, SavePaymentMethodVariables } from './__generated__/SavePaymentMethod';
import { CardInputBraintree as CardInputBraintreeComponent } from './CardInputBraintree';
import { CardInputProps } from '../../types';

const query = gql`
  query PaymentMethodToken {
    paymentMethodToken
  }
`;

const SAVE_PAYMENT_METHOD = gql`
  mutation SavePaymentMethod($nonce: String!) {
    savePaymentMethod(nonce: $nonce) {
      identifier
      isDefault
      braintreeToken
      type
      creditCardType
      imageUrl
    }
  }
`;

export const CardInputBraintree: React.FC<CardInputProps> = (props) => {
  const { loading, error, data, refetch } = useQuery(query);
  const [onSaveCard, saveData] = useMutation<SavePaymentMethod, SavePaymentMethodVariables>(SAVE_PAYMENT_METHOD, {
    onCompleted: props.onSave,
  });

  return (
    <CardInputBraintreeComponent
      title={props.title}
      onCancel={props.onCancel}
      loadingToken={loading}
      savingCard={saveData.loading}
      tokenError={error}
      saveError={saveData.error}
      retryFetchToken={refetch}
      data={data}
      onSave={onSaveCard}
      onSubmitOneTimeCard={null}
    />
  );
};
