import { Loadable } from '../Loadable';

export const AsyncFulfillmentCenterMap = Loadable(() => import('./FulfillmentCenterMap'));
export const AsyncHistory = Loadable(() => import('./OrderHistory'));
export const AsyncPastOrder = Loadable(() => import('./PastOrderDetail'));
export const AsyncOrderSummary = Loadable(() => import('./OrderSummary'));
export const AsyncOrderStatus = Loadable(() => import('./OrderStatus'));
export const AsyncOrderConfirmation = Loadable(() => import('./OrderConfirmation'));
export const AsyncUpdateDrinkDetail = Loadable(() => import('./UpdateDrinkDetail'));
export const AsyncRejectedOrder = Loadable(() => import('./RejectedOrder'));
export const AsyncPreorderConfirmation = Loadable(() => import('./PreorderConfirmation'));
export const AsyncPreOrderDetail = Loadable(() => import('./PreOrderDetail'));
export const AsyncDeliveryInput = Loadable(() => import('./DeliveryInput'));
