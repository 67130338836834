import classnames from 'classnames';
import * as React from 'react';
import { Option } from '../types';

interface RadioRowProps {
  option: Option;
  selected: boolean;
  onClick: (option: Option) => void;
}

export const RadioRow: React.FC<RadioRowProps> = ({ option, selected, onClick }) => {
  const onClickWrapped = React.useCallback(() => {
    if (!option.soldOut) {
      onClick(option);
    }
  }, [onClick, option]);

  const rowClasses = classnames('radio-menu-list-row', {
    'radio-menu-list-row--selected': selected,
    'radio-menu-list-row--disabled': option.soldOut,
  });

  return (
    <div id={`option-${option.optionId}`} onClick={onClickWrapped} className={rowClasses}>
      <div data-option-name={option.name}>
        <div className='radio-menu-list-row__radio' />
        {option.name}
      </div>
      {option.price > 0 && <span className='radio-menu-list-row__price'> + ${option.price}</span>}
      {option.soldOut && <span className='radio-menu-list-row__soldout'>Sold out</span>}
    </div>
  );
};
