import classnames from 'classnames';
import * as React from 'react';
import './DarkButton.scss';

interface DarkButtonProps {
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  onClick?: (e?: any) => void;
  onClickCapture?: () => void;
  testId?: string;
  href?: string;
}

export const DarkButton: React.FC<DarkButtonProps> = (props) => {
  const { onClickCapture, href, testId, onClick, type, className, disabled, children } = props;
  const classNameCombined = classnames('btn', 'btn-dark-blue', className);
  const Element = props.href ? 'a' : 'button';

  return (
    <Element
      href={href}
      data-test-id={testId}
      onClickCapture={onClickCapture}
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNameCombined}
    >
      {children}
    </Element>
  );
};
