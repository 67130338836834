import classnames from 'classnames';
import * as React from 'react';
import './Modal.scss';

const ModalTitle = ({ title, className }: { title: string; className?: string }) => {
  return <h3 className={classnames('modal-title', className)}>{title}</h3>;
};

const ModalText = ({ children, className }: { children: any; className?: string }) => {
  return <p className={classnames('modal-text', className)}>{children}</p>;
};

const ModalContent = ({ children, style }: { children: any; style?: any }) => {
  return (
    <div style={style} className='modal-content'>
      {children}
    </div>
  );
};

interface ModalProps {
  open: boolean;
  className?: string;
}

type IModal = React.FC<ModalProps> & {
  Title: typeof ModalTitle;
  Text: typeof ModalText;
  Content: typeof ModalContent;
};

export const Modal: IModal = (props) => {
  if (!props.open) {
    return null;
  }

  return (
    <div className='modal-overlay'>
      <div className={classnames('modal', props.className)}>{props.children}</div>
    </div>
  );
};

Modal.Title = ModalTitle;
Modal.Text = ModalText;
Modal.Content = ModalContent;
