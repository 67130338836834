import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Layout, Navbar } from '../../components';
import { NavbarButtonType } from '../../types';
import { urlUtils } from '../../utils';
import './AboutList.scss';

interface AboutListRouterParams {
  venueId: string;
}

export type RouterProps = RouteComponentProps<AboutListRouterParams>;

interface AboutListProps extends RouterProps {}

export class AboutList extends React.PureComponent<AboutListProps, {}> {
  public render() {
    const venueId = this.props.match.params.venueId;

    return (
      <Layout backgroundColorVariant='dark' title='About Noble' className='about-content'>
        <Navbar>
          <Navbar.Button to={urlUtils.venueHomeUrl({ venueId })} type={NavbarButtonType.cross} />
          <Navbar.Title>About</Navbar.Title>
        </Navbar>
        <div className='about-content'>
          <a
            className='about-link'
            rel='noopener noreferrer'
            target='_blank'
            href='https://getnoble.co/terms-of-service'
          >
            Terms of Service
          </a>
          <a className='about-link' rel='noopener noreferrer' target='_blank' href='https://getnoble.co/privacy-policy'>
            Privacy Policy
          </a>
        </div>
      </Layout>
    );
  }
}
