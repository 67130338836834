import { MenuQuery_menu_style } from './__generated__/MenuQuery';

export const DEFAULT_STYLE: MenuQuery_menu_style = {
  __typename: 'MenuStyle',
  backgroundImageUrl: null,
  categoryTitleColor: '#fff',
  categoryTiles: null,
  cartBar: {
    __typename: 'MenuStyleCartBar',
    backgroundColor: '#31318c',
    topBorder: null,
    textColor: null,
  },
  topBar: {
    __typename: 'MenuStyleTopBar',
    backgroundColor: '#31318c',
    textColor: '#fff',
    bottomBorder: {
      __typename: 'MenuStyleTopBarBottomBorder',
      width: 1,
      color: '#fff',
    },
  },
  venueTop: {
    __typename: 'MenuStyleVenueTop',
    hideShadow: false,
    hideTitle: false,
    photo: null,
  },
};
