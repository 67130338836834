import classNames from 'classnames';
import * as React from 'react';
import './Dropdown.scss';

interface DropdownProps<T> {
  value: T;
  getValue(option: T): string;
  getName(option: T): string;
  options: Array<T>;
  className?: string;
  onChange(option: T);
}

export function Dropdown<T>({ getName, getValue, value, className, options, onChange }: DropdownProps<T>) {
  const onChangeMemoized = React.useCallback(
    ({ target }) => {
      const match = options.find((option) => getValue(option) === target.value)!;
      onChange(match);
    },
    [options, onChange, getValue]
  );

  const selectedValue = getValue(value);

  return (
    <select value={selectedValue} onChange={onChangeMemoized} className={classNames('dropdown', className)}>
      {options.map((option) => (
        <option key={getValue(option)} value={getValue(option)}>
          {getName(option)}
        </option>
      ))}
    </select>
  );
}
