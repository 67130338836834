import { InMemoryCache, ApolloError } from '@apollo/client';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { CookiesProvider } from 'react-cookie';
import { createClient } from './Apollo';
import { App } from './App';
import config from './config';
import { CookieNames } from './constants';
import { analytics } from './services';
import * as Sentry from '@sentry/browser';

if (config.SEND_EVENTS) {
  Sentry.init({
    dsn: 'https://5ddb3c642a254a59b99ce4acad527a14@sentry.io/1380022',
    environment: config.API_ENV,
    release: `consumer-${process.env.RAZZLE_NOBLE_COMMIT_HASH}`,
    beforeSend(event, hint) {
      if (hint?.originalException && hint?.originalException instanceof ApolloError) {
        event.tags = event.tags ?? {};
        event.tags['is_apollo_unhandled'] = true;
        if (
          hint.originalException.graphQLErrors?.length > 0 &&
          hint.originalException.graphQLErrors[0].extensions?.display?.code
        ) {
          return null;
          // event.tags['api_error_code'] = hint.originalException.graphQLErrors[0]?.extensions.display?.code;
        }
      }

      return event;
    },
  });
}

analytics.setupGoogleAnalytics();

const cache = new InMemoryCache({
  typePolicies: {
    TimeSlot: {
      // In an inventory management system, products might be identified
      // by their UPC.
      // keyFields: ["id", "time"],
      keyFields: ['name', 'time'],
    },
  },
}).restore((window as any).__APOLLO_STATE__);

const client = createClient({
  cache,
  ssrMode: false,
  getAccessToken() {
    const cookies = new Cookies();
    return cookies.get(CookieNames.accessToken);
  },
});

hydrate(
  <CookiesProvider>
    <Router>
      <App client={client} />
    </Router>
  </CookiesProvider>,
  document.getElementById('root')
);

if ((module as any).hot) {
  (module as any).hot.accept();
}
