export const Actions = {
  SIGN_OUT: 'SIGN_OUT',

  ADD_TO_CART: 'ADD_TO_CART',
  UPDATE_CART_ITEM: 'UPDATE_CART_ITEM',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  CLEAR_ORDER: 'CLEAR_ORDER',
  UPDATE_ORDER_TIP: 'UPDATE_ORDER_TIP',
  UPDATE_ORDER_TIME_OPTION: 'UPDATE_ORDER_TIME_OPTION',
  CHANGE_DESTINATION_TYPE: 'CHANGE_DESTINATION_TYPE',
  SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',
  SELECT_PROMO_CODE: 'SELECT_PROMO_CODE',
  ACCEPT_DRINK_LIMIT: 'ACCEPT_DRINK_LIMIT',
  REMOVE_PROMO_CODE: 'REMOVE_PROMO_CODE',
  HIDE_BRAND_MODAL: 'HIDE_BRAND_MODAL',
  UPDATE_ORDER_AFTER_SAVE: 'UPDATE_ORDER_AFTER_SAVE',
  UPDATE_TICKET_ID: 'UPDATE_TICKET_ID',

  CLEAR_ALL_ORDERS: 'CLEAR_ALL_ORDERS',

  SET_ORDER: 'SET_ORDER',

  SAVE_PROMO_CODE: 'SAVE_PROMO_CODE',

  ONBOARDING_START: 'ONBOARDING_START',
};
