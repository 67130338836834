import * as React from 'react';
import { TextInput } from '../../components';

interface CommentsProps {
  value: null | string;
  onChange: (value: string) => void;
  disableComments: null | boolean;
}

export const Comments: React.FC<CommentsProps> = (props) => {
  if (props.disableComments) {
    return null;
  }

  return (
    <section>
      <h6 className='drink-detail-header-row'>Additional comments</h6>
      <TextInput
        placeholder={'E.g. Add a splash of cranberry or no cheese on my burger'}
        value={props.value || ''}
        onChange={props.onChange}
        multiline={true}
        maxLength={255}
        className={'drink-detail-comments'}
      />
    </section>
  );
};
