import * as React from 'react';
import { ErrorOverlay, Layout, Loading, Navbar } from '../../../components';
import * as types from '../../../types';
import { HPCForm } from './__generated__/HPCForm';
import { AddHPCCardToWalletVariables } from './__generated__/AddHPCCardToWallet';
import './CardInputFreedomPay.scss';

interface CardInputFreedomPayProps {
  loadingForm: boolean;
  savingCard: boolean;
  error?: Error;
  clearError();
  data?: HPCForm;
  venueId: string;
  title: string;
  onCancel();
  onSave({ variables }: { variables: AddHPCCardToWalletVariables }): Promise<any>;
}

export class CardInputFreedomPay extends React.PureComponent<CardInputFreedomPayProps> {
  messageListener = (e) => {
    const message = e.data;
    const data = message.data;

    if (message.type === 3) {
      const cardIssuerAttribute = data.attributes.find((attribute) => attribute.Key === 'CardIssuer');
      const cardType = cardIssuerAttribute ? cardIssuerAttribute.Value : null;
      this.props.onSave({
        variables: {
          venueId: this.props.venueId,
          sessionKey: this.props.data!.hpcForm.sessionKey,
          paymentKey: data.paymentKeys.pop(),
          cardType,
        },
      });
    }
  };

  componentDidMount() {
    window.addEventListener('message', this.messageListener);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener);
  }

  public render() {
    const { loadingForm, savingCard, data, onCancel, clearError } = this.props;
    if (loadingForm) {
      return <Loading />;
    }

    const htmlForm = data && data.hpcForm ? data.hpcForm.formHTML : '';

    return (
      <Layout title={this.props.title}>
        <Navbar>
          <Navbar.Button onClick={onCancel} type={types.NavbarButtonType.chevron} />
          <Navbar.Title>{this.props.title}</Navbar.Title>
        </Navbar>
        <ErrorOverlay error={this.props.error} onClickButton={clearError} />
        <div className='freedom-pay-content'>
          <div className='freedom-pay-controls' dangerouslySetInnerHTML={{ __html: htmlForm }} />
        </div>
        {savingCard && <Loading />}
      </Layout>
    );
  }
}
