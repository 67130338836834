import * as React from 'react';
import { PaymentMethodButton } from '../';

interface AddPaymentMethodButtonProps {
  onClick: () => void;
  showPlus: boolean;
  testId?: string;
}

export const AddPaymentMethodButton: React.FC<AddPaymentMethodButtonProps> = ({
  testId,
  showPlus,
  children,
  onClick,
}) => (
  <PaymentMethodButton testId={testId} className='add-payment-method-row' onClick={onClick}>
    {showPlus && <span className='add-payment-method-row-plus'>+</span>}
    {children}
  </PaymentMethodButton>
);
