import React from 'react';
import { MenuStyle } from '../menu/types';

export const getCSSPropsForBottomButton = (menuStyle: MenuStyle): React.CSSProperties => {
  const cartButtonStyle: React.CSSProperties = { backgroundColor: menuStyle.cartBar.backgroundColor };
  if (menuStyle.cartBar.topBorder) {
    cartButtonStyle.borderTop = `${menuStyle.cartBar.topBorder.width}px solid ${menuStyle.cartBar.topBorder.color}`;
  }

  if (menuStyle.cartBar.textColor) {
    cartButtonStyle.color = menuStyle.cartBar.textColor;
  }

  return cartButtonStyle;
};
