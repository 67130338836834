/* tslint:disable:max-classes-per-file */

import Cookies from 'universal-cookie';
import { WebAuth } from 'auth0-js';
import { FirebaseApp } from 'firebase/app';
import { Auth, getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { CookieNames } from '../constants';
import config from '../config';
import { setExpirationDate } from '../store';
const jwtDecode = require('jwt-decode');

const realm = 'Username-Password-Authentication';
const scope = 'openid profile email'; // read:current_user
const audience = 'backend-api';

type Tokens = {
  accessToken: string;
  idToken: string;
};

export enum AuthErrorCode {
  Unknown,
  EmailTaken,
  EmailInvalid,
  PasswordStrength,
  PasswordUserInfo,
  Phone,
  BadUsernameOrPassword,
  Other,
}

export class AuthError extends Error {
  public code: AuthErrorCode;
  constructor(code: AuthErrorCode, message: string) {
    super(message);

    this.message = message;
    this.code = code;
  }
}

export interface AuthRegistrationData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export const convertAuth0Error = (err: any) => {
  if (err.code === 'user_exists') {
    return new AuthError(AuthErrorCode.EmailTaken, 'A user with this email already exists');
  } else if (err.name === 'PasswordNoUserInfoError') {
    const msg = 'Please make sure your password does not contain your name and is not similar to your email';
    return new AuthError(AuthErrorCode.PasswordUserInfo, msg);
  } else if (err.code === 'invalid_password') {
    const message = 'Please choose a stronger password. ' + err.policy;
    return new AuthError(AuthErrorCode.PasswordStrength, message);
  } else if (err.code === 'server_error') {
    return new AuthError(AuthErrorCode.Phone, 'Please check the phone number you entered');
  } else if (err.code === 'invalid_grant') {
    return new AuthError(AuthErrorCode.BadUsernameOrPassword, 'The email or password you entered is wrong.');
  }

  return new AuthError(AuthErrorCode.Unknown, 'An unknown error has occurred');
};

interface UserRegistrationFields {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  dateOfBirth: Date;
}

const clientID = 'b9KYZfaUjrVR72Qim0H7zvDH9t1RDelz';
const domain = 'getnoble.auth0.com';

export class AuthService {
  public auth0: any;
  public firebaseAuth: Auth;
  public cookies: Cookies;

  constructor(firebaseApp: FirebaseApp) {
    this.firebaseAuth = getAuth(firebaseApp);
    this.cookies = new Cookies();
    this.auth0 = new WebAuth({
      clientID,
      domain,
      responseType: 'token id_token',
      redirectUri: `${config.SELF_URL}/social-landing`,
      audience: 'backend-api',
    });
  }

  public async resetPassword(email) {
    return this.promisify(
      this.auth0.changePassword,
      this.auth0
    )({
      email,
      connection: 'Username-Password-Authentication',
    });
  }

  public async login(email: string, password: string): Promise<Tokens> {
    const tokens = await this.loginPromisified(email, password);
    this.setTokens(tokens);

    return tokens;
  }

  public async register(fields: UserRegistrationFields) {
    await this.registerPromisified(fields);
    return this.login(fields.email, fields.password);
  }

  public loginWithFacebook() {
    return this.auth0.authorize({
      connection: 'facebook',
      ...this.loginParams(),
    });
  }

  public loginWithApple() {
    return this.auth0.authorize({
      connection: 'apple',
      ...this.loginParams(),
    });
  }

  public async loginWithFirebase(token) {
    try {
      await signInWithCustomToken(this.firebaseAuth, token);
    } catch {}
  }

  public async logout() {
    this.cookies.remove(CookieNames.accessToken);
    this.cookies.remove(CookieNames.idToken);

    try {
      await signOut(this.firebaseAuth);
    } catch {}
  }

  public async parseAccessToken(): Promise<Tokens> {
    const tokens = await this.promisify(this.auth0.parseHash, this.auth0)({ hash: window.location.hash });

    this.setTokens(tokens);
    return tokens;
  }

  public setTokens(tokenResponse: Tokens) {
    const payload = jwtDecode(tokenResponse.accessToken);
    const options = {
      expires: new Date(payload.exp * 1000),
      path: '/',
      domain: process.env.NODE_ENV !== 'production' ? undefined : '.getnoble.co',
    };

    this.cookies.set(CookieNames.accessToken, tokenResponse.accessToken, options);
    this.cookies.set(CookieNames.idToken, tokenResponse.idToken, options);
    setExpirationDate();
  }

  private loginParams() {
    return {
      scope,
      realm,
      audience,
    };
  }

  private async loginPromisified(username: string, password: string): Promise<Tokens> {
    const { accessToken, idToken } = await this.promisify(
      this.auth0.client.login,
      this.auth0.client,
      convertAuth0Error
    )({
      connection: 'Username-Password-Authentication',
      username,
      password,
      ...this.loginParams(),
    });

    return { accessToken, idToken };
  }

  private registerPromisified({ firstName, lastName, email, password, phone, dateOfBirth }: UserRegistrationFields) {
    return this.promisify(
      this.auth0.signup,
      this.auth0,
      convertAuth0Error
    )({
      connection: 'Username-Password-Authentication',
      email,
      username: email,
      password,
      scope,
      realm: 'Username-Password-Authentication',
      userMetadata: {
        first_name: firstName,
        last_name: lastName,
        name: `${firstName} ${lastName}`,
        phone_number: phone,
        date_of_birth: dateOfBirth.toISOString(),
      },
    });
  }

  private promisify(func, thisContext, errorWrapper?): (...args) => Promise<any> {
    return (...args) => {
      return new Promise((resolve, reject) => {
        const cb = (err: any, result: any) => {
          if (err) {
            const finalError = errorWrapper ? errorWrapper(err) : err;
            return reject(finalError);
          }

          return resolve(result);
        };

        func.apply(thisContext, [...args, cb]);
      });
    };
  }
}
