type AppConfig = {
  API_ENV: string;
  API_URL: string;
  FIREBASE_CONFIG: any;
  SEND_EVENTS: boolean;
  SELF_URL: string;
  BT_PREFILL: {
    number?: string;
    cvv?: string;
    expirationDate?: string;
    postalCode?: string;
  };
  SQUARE_APP_ID: string;
  APPLE_PAY_PROCESSOR: string;
  SPREEDLY_APP_ID: string;
};

let config: AppConfig;

if (process.env.BUILD_TARGET === 'client') {
  config = {
    API_ENV: (window as any).env.API_ENV,
    API_URL: (window as any).env.API_URL,
    FIREBASE_CONFIG: (window as any).env.FIREBASE_CONFIG,
    SEND_EVENTS: (window as any).env.SEND_EVENTS,
    SELF_URL: (window as any).env.SELF_URL,
    BT_PREFILL: (window as any).env.BT_PREFILL,
    SQUARE_APP_ID: (window as any).env.SQUARE_APP_ID,
    APPLE_PAY_PROCESSOR: (window as any).env.APPLE_PAY_PROCESSOR,
    SPREEDLY_APP_ID: (window as any).env.SPREEDLY_APP_ID,
  };
} else {
  const firebaseConfigs = {
    local: {
      apiKey: 'AIzaSyDUzmBQipO5rQIqK3L-A3bGv81loHdQPC4',
      authDomain: 'noble-development.firebaseapp.com',
      databaseURL: 'https://noble-development.firebaseio.com',
      projectId: 'noble-development',
      storageBucket: 'noble-development.appspot.com',
      messagingSenderId: '70601302345',
      appId: '1:70601302345:web:aba99da7019d14dace3a98',
      measurementId: 'G-HS23WMLXP9',
    },
    development: {
      apiKey: 'AIzaSyDUzmBQipO5rQIqK3L-A3bGv81loHdQPC4',
      authDomain: 'noble-development.firebaseapp.com',
      databaseURL: 'https://noble-development.firebaseio.com',
      projectId: 'noble-development',
      storageBucket: 'noble-development.appspot.com',
      messagingSenderId: '70601302345',
      appId: '1:70601302345:web:aba99da7019d14dace3a98',
      measurementId: 'G-HS23WMLXP9',
    },
    staging: {
      apiKey: 'AIzaSyD91V6ZC4y9w5WXAXmhhOV29ui7w4M3P0k',
      authDomain: 'noble-staging.firebaseapp.com',
      databaseURL: 'https://noble-staging.firebaseio.com',
      projectId: 'noble-staging',
      storageBucket: 'noble-staging.appspot.com',
      messagingSenderId: '439572467060',
      appId: '1:439572467060:web:9166ead2c3900f6e',
      measurementId: 'G-HS23WMLXP9',
    },
    production: {
      apiKey: 'AIzaSyAwtyPFqBOqX5Ola7cDU4hWovURq4NK_tk',
      authDomain: 'noble-a7844.firebaseapp.com',
      databaseURL: 'https://noble-a7844.firebaseio.com',
      projectId: 'noble-a7844',
      storageBucket: 'noble-a7844.appspot.com',
      messagingSenderId: '469080764821',
      appId: '1:469080764821:web:00eb4003ad267a6e',
      measurementId: 'G-DDQK4R98FH',
    },
  };

  const apis = {
    local: 'http://localhost:5000',
    development: 'https://api.dev.getnoble.co',
    staging: 'https://staging-api.getnoble.co',
    production: 'https://api.getnoble.co',
  };

  const selfUrl = {
    local: 'http://localhost:3000',
    development: 'https://order.dev.getnoble.co',
    staging: 'https://order.stg.getnoble.co',
    production: 'https://order.getnoble.co',
  };

  const env = process.env.API_ENV || 'local';

  config = {
    API_ENV: env,
    API_URL: apis[env],
    SELF_URL: selfUrl[env],
    FIREBASE_CONFIG: firebaseConfigs[env],
    SEND_EVENTS: process.env.NODE_ENV !== 'development',
    SQUARE_APP_ID: env === 'production' ? 'sq0idp-bhSs3HscycN7u_xTnPC8dw' : 'sandbox-sq0idb-P9wAMXU5vpItHQUgGbG2sA',
    SPREEDLY_APP_ID: env === 'production' ? 'QpCoBB2PZIJwybJPWElZV2Rh4Ip' : '2gV9JB8u4yz0MVe8FHt359Ur5Pk',
    BT_PREFILL: process.env.INTEGRATION_TESTS
      ? {
          number: '4111111111111111',
          cvv: '905',
          expirationDate: '10/2023',
          postalCode: '11211',
        }
      : {},
    APPLE_PAY_PROCESSOR: 'square',
  };
}

export default config;
