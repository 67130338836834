import * as React from 'react';
import './Checkbox.scss';

type CheckboxProps = {
  checked: boolean;
  label: string;
  id: string;
  onChange(checked: boolean);
};

export const Checkbox = ({ label, id, checked, onChange }: CheckboxProps) => {
  const onChangeEventHandler = React.useCallback(
    (e) => {
      onChange(e.target.checked);
    },
    [onChange]
  );

  return (
    <>
      <input type='checkbox' id={id} checked={checked} onChange={onChangeEventHandler} className='checkbox-input' />
      <label htmlFor={id} className='checkbox-label'>
        {label}
      </label>
    </>
  );
};
