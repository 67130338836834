import * as React from 'react';
// import { setDate } from 'date-fns';
import RCalendar from 'react-calendar';
import './Calendar.scss';

interface CalendarProps {
  value: Date;
  minimumDate?: Date;
  maximumDate?: Date;
  onChange(newDate: Date);
}

export const Calendar = ({ value, onChange, minimumDate, maximumDate }: CalendarProps) => {
  const onCancel = React.useCallback(
    (e) => {
      if (e.target.id === 'date-calendar') {
        onChange(value);
      }
    },
    [value, onChange]
  );

  // const minMonth = minimumDate ? setDate(minimumDate, 1) : undefined;

  return (
    <div id='date-calendar' onClickCapture={onCancel} className='calendar-overlay'>
      <RCalendar
        value={value}
        minDate={minimumDate || new Date()}
        maxDate={maximumDate}
        minDetail='month'
        onChange={onChange}
      />
    </div>
  );
};
