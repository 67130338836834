import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute, AdminRoute } from './components';

import { urlUtils } from './utils';

import * as aboutRoutes from './about';
import * as accountRoutes from './account';
import { AsyncNotFound } from './core';
import * as adminRoutes from './dev';
import * as homeRoutes from './home';
import * as menuRoutes from './menu';
import * as onboardingRoutes from './onboarding';
import * as orderRoutes from './order';
import * as paymentMethodsRoutes from './payments';
import * as promoCodeRoutes from './promos';
import * as supportRoutes from './support';
import * as ratingRoutes from './rating';

export default () => (
  <Switch>
    <Route component={homeRoutes.AsyncVenueList} path='/' exact={true} />
    <Route component={homeRoutes.AsyncVenueHome} path={urlUtils.venueHomeUrl.pattern} exact={true} />
    <Route component={homeRoutes.AsyncWelcomeScreen} path={urlUtils.venueWelcome.pattern} exact={true} />
    <Route component={menuRoutes.AsyncMenu} path={urlUtils.menuUrl.pattern} exact={true} />
    <Route
      component={menuRoutes.AsyncDrinkDetail}
      path={urlUtils.createPlaceholderUrl(urlUtils.drinkUrl, [
        'venueId',
        'fulfillmentCenterId',
        'categoryId',
        'itemId',
      ])}
      exact={true}
    />
    <Route
      component={orderRoutes.AsyncOrderSummary}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderSummaryUrl, ['venueId', 'fulfillmentCenterId'])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncOrderStatus}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderStatusUrl, ['venueId', 'fulfillmentCenterId', 'orderId'])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncRejectedOrder}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderRejectedUrl, ['venueId', 'fulfillmentCenterId', 'orderId'])}
      exact={true}
    />

    <PrivateRoute
      component={paymentMethodsRoutes.AsyncPaymentMethodsList}
      path={urlUtils.createPlaceholderUrl(urlUtils.paymentMethodsMaintenanceUrl, ['venueId', 'paymentMethodType'])}
      exact={true}
    />
    <PrivateRoute
      component={paymentMethodsRoutes.AsyncPaymentMethodsList}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderPaymentMethodsUrl, [
        'venueId',
        'fulfillmentCenterId',
        'paymentMethodType',
      ])}
      exact={true}
    />
    <PrivateRoute
      component={paymentMethodsRoutes.AsyncAddCreditCard}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderAddCreditCardUrl, [
        'venueId',
        'fulfillmentCenterId',
        'paymentMethodType',
      ])}
      exact={true}
    />
    <PrivateRoute
      component={paymentMethodsRoutes.AsyncReenterCreditCard}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderReenterPaymentMethodsUrl, [
        'venueId',
        'fulfillmentCenterId',
        'paymentMethodType',
      ])}
      exact={true}
    />
    <PrivateRoute
      component={promoCodeRoutes.AsyncPromoCodeList}
      path={urlUtils.createPlaceholderUrl(urlUtils.promoMethodsMaintenanceUrl, ['venueId'])}
      exact={true}
    />
    <PrivateRoute
      component={promoCodeRoutes.AsyncPromoCodeList}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderPromoMethodsUrl, ['venueId', 'fulfillmentCenterId'])}
      exact={true}
    />
    <PrivateRoute
      component={promoCodeRoutes.AsyncAddPromoCode}
      path={urlUtils.createPlaceholderUrl(urlUtils.addPromoMethodsMaintenanceUrl, ['venueId'])}
      exact={true}
    />
    <PrivateRoute
      component={promoCodeRoutes.AsyncAddPromoCode}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderAddPromoMethodsUrl, ['venueId', 'fulfillmentCenterId'])}
      exact={true}
    />
    <PrivateRoute
      component={paymentMethodsRoutes.AsyncAddCreditCard}
      path={urlUtils.createPlaceholderUrl(urlUtils.addPaymentMethodsMaintenanceUrl, ['venueId', 'paymentMethodType'])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncOrderConfirmation}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderConfirmationUrl, ['venueId', 'fulfillmentCenterId', 'orderId'])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncPreorderConfirmation}
      path={urlUtils.createPlaceholderUrl(urlUtils.preOrderConfirmationUrl, [
        'venueId',
        'fulfillmentCenterId',
        'orderId',
      ])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncFulfillmentCenterMap}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderMapUrl, ['venueId', 'fulfillmentCenterId', 'orderId'])}
      exact={true}
    />
    <Route
      component={orderRoutes.AsyncUpdateDrinkDetail}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderUpdateDrinkDetailUrl, [
        'venueId',
        'fulfillmentCenterId',
        'index',
      ])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncHistory}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderHistoryUrl, ['venueId'])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncPastOrder}
      path={urlUtils.createPlaceholderUrl(urlUtils.pastOrderDetailUrl, ['venueId', 'orderId'])}
      exact={true}
    />
    <PrivateRoute
      component={orderRoutes.AsyncPreOrderDetail}
      path={urlUtils.createPlaceholderUrl(urlUtils.preOrderDetailUrl, ['venueId', 'orderId'])}
      exact={true}
    />
    <Route
      component={orderRoutes.AsyncDeliveryInput}
      path={urlUtils.createPlaceholderUrl(urlUtils.deliveryInputUrl, ['venueId', 'fulfillmentCenterId', 'orderId'])}
      exact={true}
    />

    <PrivateRoute component={accountRoutes.AsyncAccountInfo} path={urlUtils.accountInfo.pattern} exact={true} />
    <PrivateRoute component={accountRoutes.AsyncEditAccount} path={urlUtils.editAccount.pattern} exact={true} />

    <Route component={aboutRoutes.AboutList} path={urlUtils.about.pattern} exact={true} />

    <Route component={supportRoutes.AsyncSend} path={urlUtils.supportUrl.pattern} exact={true} />
    <Route component={supportRoutes.AsyncConfirmation} path={urlUtils.supportConfirmationUrl.pattern} />

    <Route
      component={onboardingRoutes.AsyncRegister}
      path={urlUtils.createPlaceholderUrl(urlUtils.registerUrl, ['venueId', 'fulfillmentCenterId'])}
    />
    <Route
      component={onboardingRoutes.AsyncProfileSetup}
      path={urlUtils.createPlaceholderUrl(urlUtils.onboardingProfileSetup, ['venueId', 'fulfillmentCenterId'])}
    />
    <Route
      component={onboardingRoutes.AsyncVerifyPhoneNumberPasswordless}
      path={urlUtils.createPlaceholderUrl(urlUtils.onboardingVerifyPhoneNumberPasswordless, [
        'venueId',
        'fulfillmentCenterId',
      ])}
    />
    <Route
      component={onboardingRoutes.AsyncLogin}
      path={urlUtils.createPlaceholderUrl(urlUtils.onboardingEmailLogin, ['venueId', 'fulfillmentCenterId'])}
    />
    <Route
      component={onboardingRoutes.AsyncForgotPassword}
      path={urlUtils.createPlaceholderUrl(urlUtils.onboardingForgotPasswordUrl, ['venueId', 'fulfillmentCenterId'])}
    />
    <Route component={onboardingRoutes.AsyncSocialLanding} path='/social-landing' />
    <Route
      component={onboardingRoutes.AsyncSplash}
      path={urlUtils.createPlaceholderUrl(urlUtils.onboardingUrl, ['venueId', 'fulfillmentCenterId'])}
    />
    <PrivateRoute
      component={ratingRoutes.AsyncOrderExperienceRating}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderRatingUrl, ['venueId', 'fulfillmentCenterId', 'orderId'])}
    />
    <Route
      component={ratingRoutes.AsyncOrderExperienceRating}
      path={urlUtils.createPlaceholderUrl(urlUtils.orderRatingEmailUrl, ['venueId', 'ratingUrl'])}
    />
    <AdminRoute component={adminRoutes.AsyncForceError} path='/dev/force-error' />
    <AdminRoute component={adminRoutes.AsyncClearAllOrders} path='/dev/clear-orders' />
    <AdminRoute component={adminRoutes.AsyncCheckPOSPrice} path='/dev/test-pos-prices' />
    <Route component={homeRoutes.AsyncVenueHomeCustom} path={urlUtils.venueCustomHome.pattern} />
    <Route component={AsyncNotFound} />
  </Switch>
);
