import classnames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface NavbarTextButtonProps {
  children: any;
  className?: string;
  onClick?: () => void;
  to?: string;
}

export const NavbarTextButton = (props: NavbarTextButtonProps) => {
  const classes = classnames('navbar-button', props.className);
  if (props.onClick) {
    return (
      <div className={classes} onClick={props.onClick}>
        {props.children}
      </div>
    );
  } else {
    return (
      <Link className={classes} to={props.to!}>
        {props.children}
      </Link>
    );
  }
};
