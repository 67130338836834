import * as React from 'react';
import { realtimeDatabase } from '../services';
import { onValue, off } from 'firebase/database';

export const useFirebaseDatabaseRef = <D extends any>(
  refPath: string,
  defaultData: D,
  transform: (state: any) => D
) => {
  const [data, setData] = React.useState<D>(defaultData);
  const ref = React.useRef(realtimeDatabase.ref(refPath));
  React.useEffect(() => {
    onValue(ref.current, (snapshot) => {
      const snapshotValue = snapshot.val();

      if (snapshotValue) {
        setData(transform(snapshotValue));
      }
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      off(ref.current);
    };
  }, [setData, transform]);

  return data;
};
