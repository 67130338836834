import { Order, PromoCode } from '@getnoble/noble-consumer-shared';

export enum NavbarButtonType {
  chevron = 'chevron',
  cross = 'cross',
  hamburger = 'hamburger',
}

export type IndividualOrderState = {
  order: Order;
  showBrandSplitModal: boolean;
  preorderId?: string;
};

export type OrderState = {
  [fulfillmentCenterId: string]: IndividualOrderState;
};

export interface PromoCodesState {
  promoCodes: PromoCode[];
}

export type OnboardingState = {
  from: string;
  venueId: string;
  fulfillmentCenterId?: string;
};

export interface AppState {
  promoCodes: PromoCodesState;
  orders: OrderState;
  onboarding: OnboardingState | null;
}

export enum OrderMode {
  reorder = 'reorder',
  preorder = 'preorder',
  new = 'new',
}

export enum FulfillmentCenterStatus {
  Open = 'open',
  Closed = 'closed',
  Paused = 'paused',
}
