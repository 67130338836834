import * as React from 'react';
import './Loading.scss';

interface LoadingProps {
  text?: string;
  showIndicator?: boolean;
}

export const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <div className='loading'>
      {props.showIndicator && (
        <div className='loading-dot-container'>
          <div className='loading-dot-flashing' />
        </div>
      )}
      <p>{props.text || 'Loading...'}</p>
    </div>
  );
};
