import { Action, applyMiddleware, combineReducers, createStore } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { DefaultState } from './constants';
import { reducers } from './reducers';
import { AppState } from './types';
import { Logger } from './Logger';

const reset: any = () => DefaultState;

const migrations = {
  0: reset,
  1: reset,
  2: reset,
  3: reset,
  4: reset,
};

const persistConfig = {
  key: 'root',
  storage,
  migrate: createMigrate(migrations),
  version: 4,
};

const persistedReducer: any = persistReducer(persistConfig, combineReducers(reducers));

const middlewareArg = applyMiddleware.apply(null, [thunk as any]);
export const store = createStore<AppState, Action<any>, {}, {}>(persistedReducer, DefaultState, middlewareArg);
export const persistor = persistStore(store);

const EXPIRE_KEY = '__storage_expiration';

export const setExpirationDate = () => {
  try {
    window.localStorage.setItem(EXPIRE_KEY, `${new Date(Date.now() + 86400 * 1000).getTime()}`);
  } catch (error) {
    Logger.error(error);
  }
};

if (process.env.BUILD_TARGET === 'client') {
  const checkExpiration = async () => {
    try {
      if (
        Number(window.localStorage.getItem(EXPIRE_KEY)) &&
        Number(window.localStorage.getItem(EXPIRE_KEY)) <= Date.now()
      ) {
        persistor.purge();
        setExpirationDate();
      }
    } catch (error) {
      Logger.error(error);
    }
  };

  checkExpiration();

  setTimeout(checkExpiration, 1000 * 60 * 30);

  // (window as any).clearOrders = () => {
  //   store.dispatch({
  //     type: Actions.CLEAR_ALL_ORDERS,
  //   });
  // };
}
